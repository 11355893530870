import * as React from 'react';
import {Stack, TextFieldProps} from '@mui/material';
import {TextField} from "../wrapper/MuiWrappers";

type FunctionalTextAreaProps = {
  minRows?: number;
  children?: React.ReactNode | React.ReactNode[];
  inputSlotProps?: any;
  extraSlotProps?: any;
} & Omit<TextFieldProps, "multiline">;
const FunctionalTextArea = ({
                              minRows = 1,
                              children,
                              inputSlotProps = {},
                              extraSlotProps = {},
                              ...rest
                            }: FunctionalTextAreaProps) => {
  return <TextField
      slotProps={{
        input: {
          endAdornment: (
              <Stack direction={"row"} alignItems={"center"} sx={{alignSelf: "flex-end"}}>
                {children}
              </Stack>
          ),
          ...inputSlotProps
        },
        ...extraSlotProps
      }}
      minRows={minRows}
      fullWidth
      multiline
      {...rest}
  />
};

export default FunctionalTextArea;