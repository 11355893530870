import {isMobile} from "react-device-detect";

export const DTARGET = isMobile ? "_self" : "_blank";

export const menuItemStyle = {
  backgroundColor: "transparent",
  margin: "16px",
  padding: "0px",
}

export const openExternalLink = (link: string) => {
  window.open(link, DTARGET);
}