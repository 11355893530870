// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.2
//   protoc               v5.28.0
// source: mydimension/framework/comment/CommentMessage.proto

/* eslint-disable */
import {BinaryReader, BinaryWriter} from "@bufbuild/protobuf/wire";
import {PageQuery, PageResult} from "../QueryMessage";
import {ResourceType, resourceTypeFromJSON, resourceTypeToJSON} from "../ResourceMessage";
import {CollectorTargetProfile, UserCollectState} from "../collector/CollectorMessage";
import {CustomerProfile} from "../customer/CustomerInfo";

export const protobufPackage = "proto.framework.comment";

export interface CommentRequest {
  /** 评论内容 */
  content: string;
  /** 评论对象的类型 */
  targetType: ResourceType;
  /** 评论对象的id */
  targetId: string;
  /** 可选回复的评论id */
  replyTo?:
    | string
    | undefined;
  /** 二级评论的一级评论id 有reply_to时必填 */
  attachToId?: string | undefined;
}

export interface CommentQuery {
  /** 评论对象的类型 */
  targetType: ResourceType;
  /** 评论对象的id */
  targetId: string;
  /** 查询一级评论的二级评论，填写一级评论的id */
  attachToId?:
    | string
    | undefined;
  /** 分页查询 */
  pageQuery: PageQuery | undefined;
}

export interface CommentProfile {
  commentId: string;
  owner: CustomerProfile | undefined;
  content: string;
  attachCount: number;
  /** 可选回复的评论, 最多递归1层 */
  replyTo?:
    | CommentProfile
    | undefined;
  /** 评论对象的被收藏统计信息 */
  collectProfile?:
    | CollectorTargetProfile
    | undefined;
  /** 当前用户对此评论的收藏状态 */
  userCollectState?:
    | UserCollectState
    | undefined;
  /** 评论时间 */
  createAt: number;
}

export interface CommentResponse {
  comments: CommentProfile[];
  /** 评论对象的类型 */
  targetType: ResourceType;
  /** 评论对象的id */
  targetId: string;
  pageResult: PageResult | undefined;
}

function createBaseCommentRequest(): CommentRequest {
  return { content: "", targetType: 0, targetId: "", replyTo: undefined, attachToId: undefined };
}

export const CommentRequest = {
  encode(message: CommentRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.content !== "") {
      writer.uint32(10).string(message.content);
    }
    if (message.targetType !== 0) {
      writer.uint32(16).int32(message.targetType);
    }
    if (message.targetId !== "") {
      writer.uint32(26).string(message.targetId);
    }
    if (message.replyTo !== undefined) {
      writer.uint32(34).string(message.replyTo);
    }
    if (message.attachToId !== undefined) {
      writer.uint32(42).string(message.attachToId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CommentRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.content = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.targetType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.targetId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.replyTo = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.attachToId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommentRequest {
    return {
      content: isSet(object.content) ? globalThis.String(object.content) : "",
      targetType: isSet(object.targetType) ? resourceTypeFromJSON(object.targetType) : 0,
      targetId: isSet(object.targetId) ? globalThis.String(object.targetId) : "",
      replyTo: isSet(object.replyTo) ? globalThis.String(object.replyTo) : undefined,
      attachToId: isSet(object.attachToId) ? globalThis.String(object.attachToId) : undefined,
    };
  },

  toJSON(message: CommentRequest): unknown {
    const obj: any = {};
    if (message.content !== "") {
      obj.content = message.content;
    }
    if (message.targetType !== 0) {
      obj.targetType = resourceTypeToJSON(message.targetType);
    }
    if (message.targetId !== "") {
      obj.targetId = message.targetId;
    }
    if (message.replyTo !== undefined) {
      obj.replyTo = message.replyTo;
    }
    if (message.attachToId !== undefined) {
      obj.attachToId = message.attachToId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommentRequest>, I>>(base?: I): CommentRequest {
    return CommentRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommentRequest>, I>>(object: I): CommentRequest {
    const message = createBaseCommentRequest();
    message.content = object.content ?? "";
    message.targetType = object.targetType ?? 0;
    message.targetId = object.targetId ?? "";
    message.replyTo = object.replyTo ?? undefined;
    message.attachToId = object.attachToId ?? undefined;
    return message;
  },
};

function createBaseCommentQuery(): CommentQuery {
  return { targetType: 0, targetId: "", attachToId: undefined, pageQuery: undefined };
}

export const CommentQuery = {
  encode(message: CommentQuery, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.targetType !== 0) {
      writer.uint32(8).int32(message.targetType);
    }
    if (message.targetId !== "") {
      writer.uint32(18).string(message.targetId);
    }
    if (message.attachToId !== undefined) {
      writer.uint32(26).string(message.attachToId);
    }
    if (message.pageQuery !== undefined) {
      PageQuery.encode(message.pageQuery, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CommentQuery {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommentQuery();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.targetType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.targetId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.attachToId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pageQuery = PageQuery.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommentQuery {
    return {
      targetType: isSet(object.targetType) ? resourceTypeFromJSON(object.targetType) : 0,
      targetId: isSet(object.targetId) ? globalThis.String(object.targetId) : "",
      attachToId: isSet(object.attachToId) ? globalThis.String(object.attachToId) : undefined,
      pageQuery: isSet(object.pageQuery) ? PageQuery.fromJSON(object.pageQuery) : undefined,
    };
  },

  toJSON(message: CommentQuery): unknown {
    const obj: any = {};
    if (message.targetType !== 0) {
      obj.targetType = resourceTypeToJSON(message.targetType);
    }
    if (message.targetId !== "") {
      obj.targetId = message.targetId;
    }
    if (message.attachToId !== undefined) {
      obj.attachToId = message.attachToId;
    }
    if (message.pageQuery !== undefined) {
      obj.pageQuery = PageQuery.toJSON(message.pageQuery);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommentQuery>, I>>(base?: I): CommentQuery {
    return CommentQuery.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommentQuery>, I>>(object: I): CommentQuery {
    const message = createBaseCommentQuery();
    message.targetType = object.targetType ?? 0;
    message.targetId = object.targetId ?? "";
    message.attachToId = object.attachToId ?? undefined;
    message.pageQuery = (object.pageQuery !== undefined && object.pageQuery !== null)
      ? PageQuery.fromPartial(object.pageQuery)
      : undefined;
    return message;
  },
};

function createBaseCommentProfile(): CommentProfile {
  return {
    commentId: "",
    owner: undefined,
    content: "",
    attachCount: 0,
    replyTo: undefined,
    collectProfile: undefined,
    userCollectState: undefined,
    createAt: 0,
  };
}

export const CommentProfile = {
  encode(message: CommentProfile, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.commentId !== "") {
      writer.uint32(10).string(message.commentId);
    }
    if (message.owner !== undefined) {
      CustomerProfile.encode(message.owner, writer.uint32(18).fork()).join();
    }
    if (message.content !== "") {
      writer.uint32(26).string(message.content);
    }
    if (message.attachCount !== 0) {
      writer.uint32(32).int32(message.attachCount);
    }
    if (message.replyTo !== undefined) {
      CommentProfile.encode(message.replyTo, writer.uint32(42).fork()).join();
    }
    if (message.collectProfile !== undefined) {
      CollectorTargetProfile.encode(message.collectProfile, writer.uint32(50).fork()).join();
    }
    if (message.userCollectState !== undefined) {
      UserCollectState.encode(message.userCollectState, writer.uint32(58).fork()).join();
    }
    if (message.createAt !== 0) {
      writer.uint32(64).int64(message.createAt);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CommentProfile {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommentProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.commentId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.owner = CustomerProfile.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.content = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.attachCount = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.replyTo = CommentProfile.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.collectProfile = CollectorTargetProfile.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.userCollectState = UserCollectState.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.createAt = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommentProfile {
    return {
      commentId: isSet(object.commentId) ? globalThis.String(object.commentId) : "",
      owner: isSet(object.owner) ? CustomerProfile.fromJSON(object.owner) : undefined,
      content: isSet(object.content) ? globalThis.String(object.content) : "",
      attachCount: isSet(object.attachCount) ? globalThis.Number(object.attachCount) : 0,
      replyTo: isSet(object.replyTo) ? CommentProfile.fromJSON(object.replyTo) : undefined,
      collectProfile: isSet(object.collectProfile) ? CollectorTargetProfile.fromJSON(object.collectProfile) : undefined,
      userCollectState: isSet(object.userCollectState) ? UserCollectState.fromJSON(object.userCollectState) : undefined,
      createAt: isSet(object.createAt) ? globalThis.Number(object.createAt) : 0,
    };
  },

  toJSON(message: CommentProfile): unknown {
    const obj: any = {};
    if (message.commentId !== "") {
      obj.commentId = message.commentId;
    }
    if (message.owner !== undefined) {
      obj.owner = CustomerProfile.toJSON(message.owner);
    }
    if (message.content !== "") {
      obj.content = message.content;
    }
    if (message.attachCount !== 0) {
      obj.attachCount = Math.round(message.attachCount);
    }
    if (message.replyTo !== undefined) {
      obj.replyTo = CommentProfile.toJSON(message.replyTo);
    }
    if (message.collectProfile !== undefined) {
      obj.collectProfile = CollectorTargetProfile.toJSON(message.collectProfile);
    }
    if (message.userCollectState !== undefined) {
      obj.userCollectState = UserCollectState.toJSON(message.userCollectState);
    }
    if (message.createAt !== 0) {
      obj.createAt = Math.round(message.createAt);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommentProfile>, I>>(base?: I): CommentProfile {
    return CommentProfile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommentProfile>, I>>(object: I): CommentProfile {
    const message = createBaseCommentProfile();
    message.commentId = object.commentId ?? "";
    message.owner = (object.owner !== undefined && object.owner !== null)
      ? CustomerProfile.fromPartial(object.owner)
      : undefined;
    message.content = object.content ?? "";
    message.attachCount = object.attachCount ?? 0;
    message.replyTo = (object.replyTo !== undefined && object.replyTo !== null)
      ? CommentProfile.fromPartial(object.replyTo)
      : undefined;
    message.collectProfile = (object.collectProfile !== undefined && object.collectProfile !== null)
      ? CollectorTargetProfile.fromPartial(object.collectProfile)
      : undefined;
    message.userCollectState = (object.userCollectState !== undefined && object.userCollectState !== null)
      ? UserCollectState.fromPartial(object.userCollectState)
      : undefined;
    message.createAt = object.createAt ?? 0;
    return message;
  },
};

function createBaseCommentResponse(): CommentResponse {
  return { comments: [], targetType: 0, targetId: "", pageResult: undefined };
}

export const CommentResponse = {
  encode(message: CommentResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.comments) {
      CommentProfile.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.targetType !== 0) {
      writer.uint32(16).int32(message.targetType);
    }
    if (message.targetId !== "") {
      writer.uint32(26).string(message.targetId);
    }
    if (message.pageResult !== undefined) {
      PageResult.encode(message.pageResult, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CommentResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommentResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.comments.push(CommentProfile.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.targetType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.targetId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pageResult = PageResult.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommentResponse {
    return {
      comments: globalThis.Array.isArray(object?.comments)
        ? object.comments.map((e: any) => CommentProfile.fromJSON(e))
        : [],
      targetType: isSet(object.targetType) ? resourceTypeFromJSON(object.targetType) : 0,
      targetId: isSet(object.targetId) ? globalThis.String(object.targetId) : "",
      pageResult: isSet(object.pageResult) ? PageResult.fromJSON(object.pageResult) : undefined,
    };
  },

  toJSON(message: CommentResponse): unknown {
    const obj: any = {};
    if (message.comments?.length) {
      obj.comments = message.comments.map((e) => CommentProfile.toJSON(e));
    }
    if (message.targetType !== 0) {
      obj.targetType = resourceTypeToJSON(message.targetType);
    }
    if (message.targetId !== "") {
      obj.targetId = message.targetId;
    }
    if (message.pageResult !== undefined) {
      obj.pageResult = PageResult.toJSON(message.pageResult);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommentResponse>, I>>(base?: I): CommentResponse {
    return CommentResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommentResponse>, I>>(object: I): CommentResponse {
    const message = createBaseCommentResponse();
    message.comments = object.comments?.map((e) => CommentProfile.fromPartial(e)) || [];
    message.targetType = object.targetType ?? 0;
    message.targetId = object.targetId ?? "";
    message.pageResult = (object.pageResult !== undefined && object.pageResult !== null)
      ? PageResult.fromPartial(object.pageResult)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
