import * as React from 'react';
import {useCallback} from 'react';
import {ResourceType} from "../../proto/framework/ResourceMessage";
import {IconButton, Pagination, Stack, StackProps} from "@mui/material";
import {CommentProfile} from "../../proto/framework/comment/CommentMessage";
import {PageResult} from "../../proto/framework/QueryMessage";
import {commentOn, getLikeCount, queryComment, SortType} from "./CommentService";
import {Typography} from "../../component/wrapper/MuiWrappers";
import ProfileAvatar from "../customer/component/ProfileAvatar";
import {useAppSelector} from "../../api/redux/hooks";
import {selectLoginResponse} from "../../api/redux/slice/SettingSlice";
import EmojiTextField from "../../component/input/EmojiTextField";
import SendIcon from "@mui/icons-material/Send";
import AlertContext from "../../context/AlertContext";
import ListComponent from "../../component/container/ListComponent";
import {objectIdToTimestamp} from "../../api/util/TimeUtil";
import CommentItem from "./component/CommentItem";
import ContentButton from "../../component/button/ContentButton";
import {useIsAdmin} from "../../hook/CommonUiHook";

type CommentWidgetProps = {
  objectId: string;
  targetType: ResourceType;
  page?: number;
  pageSize?: number;
  title?: string;
  canDelete?: boolean;
} & StackProps
const CommentWidget = ({

                         objectId,
                         targetType,
                         page = 0,
                         pageSize = 30,
                         title="Comments",
                         canDelete = false,
                         sx,
                         ...rest
                       }: CommentWidgetProps) => {
  const isAdmin = useIsAdmin()
  const currentLoginResponse = useAppSelector(selectLoginResponse);
  const {showAlert} = React.useContext(AlertContext);
  const [comments, setComments] = React.useState<CommentProfile[]>([]);
  const [sortMethod, setSortMethod] = React.useState<SortType>("time");
  const defaultPageResult = PageResult.create();
  defaultPageResult.page = page;
  defaultPageResult.pageSize = pageSize;
  defaultPageResult.totalPage = 0;
  defaultPageResult.total = 0;
  const [pageResult, setPageResult] = React.useState<PageResult>(defaultPageResult);
  const [userComment, setUserComment] = React.useState<string>('');

  React.useEffect(() => {
    queryComment(targetType, objectId, page, pageSize).then((response) => {
      setComments(response.comments);
      if (response.pageResult) {
        setPageResult(response.pageResult);
      }
    }).catch((e) => {
      showAlert(e.message, "error");
    });
  }, [objectId, targetType, page, pageSize]);

  React.useEffect(() => {
    setComments([...comments].sort((a, b) => {
      if (sortMethod === "time") {
        return objectIdToTimestamp(b.commentId) - objectIdToTimestamp(a.commentId);
      } else {
        return getLikeCount(b) - getLikeCount(a);
      }
    }));
  }, [sortMethod]);

  const sendMainComment = useCallback((content: string) => {
    if (!content || content.length === 0 || content.trim().length === 0) {
      showAlert("Comment cannot be empty", "warning");
      return Promise.resolve();
    }
    return commentOn(content, targetType, objectId, void 0).then((newComment) => {
      setComments([newComment, ...comments]);
    });
  }, [comments, objectId, targetType]);

  return <Stack direction={"column"} width={"100%"} spacing={3} sx={{...sx}} {...rest}>
    <Stack direction={"row"} alignItems={"baseline"} justifyContent={"flex-start"} spacing={2}>
      <Typography variant={"h5"}>{title}</Typography>
      <Typography variant={"body2"}>{pageResult.total}</Typography>
      <ContentButton
          title={"Time"}
          variant={sortMethod === "time" ? "contained" : "text"}
          color={"primary"}
          disableElevation={true}
          onClick={() => {
            setSortMethod("time");
          }}></ContentButton>
      <Typography variant={"body2"} enableTranslate={false}>|</Typography>
      <ContentButton
          title={"Like"}
          variant={sortMethod === "like" ? "contained" : "text"}
          color={"primary"}
          disableElevation={true}
          onClick={() => {
            setSortMethod("like");
          }}></ContentButton>
    </Stack>
    {currentLoginResponse &&
        <Stack direction={"row"} alignItems={"flex-start"} justifyContent={"flex-start"}
               spacing={2}>
          <ProfileAvatar title={currentLoginResponse?.name}
                         avatarUrl={currentLoginResponse?.avatarUrl}/>
          <EmojiTextField text={userComment} setText={setUserComment}
                          placeholder={"Leave a comment"}>
            <IconButton onClick={() => {
              sendMainComment(userComment).then(() => {
                setUserComment('');
              }).catch((error) => {
                showAlert(error.message, "error");
              });

            }}>
              <SendIcon/>
            </IconButton>
          </EmojiTextField>
        </Stack>}
    <Stack direction={"column"} justifyContent={"flex-start"} width={"100%"} spacing={2}>
      <ListComponent data={comments} renderItem={(comment, index) => {
        return <CommentItem
            targetType={targetType}
            targetId={objectId}
            showDivider={true}
            canDelete={canDelete || isAdmin}
            onDeleted={() => {
              setComments(comments.filter((item) => {
                return item.commentId !== comment.commentId;
              }));
            }}
            comment={comment} key={comment.commentId + ":" + index}/>;
      }}/>
    </Stack>

    {pageResult.totalPage > 1 &&
        <Pagination count={pageResult.totalPage}
                    page={pageResult.page + 1}
                    onChange={(event, value) => {
                      queryComment(targetType, objectId, value - 1, pageSize).then((response) => {
                        setComments(response.comments);
                        if (response.pageResult) {
                          setPageResult(response.pageResult);
                        }
                      }).catch((e) => {
                        showAlert("Failed to load comments", "error");
                      });
                    }}/>}

  </Stack>;
};

export default CommentWidget;