import React, {useCallback, useEffect, useState} from "react";
import {apiHost, str_strPost} from "../../api/util/MyDimensionClient";
import Stomp, {Client, Frame} from "stompjs"
import SockJS from "sockjs-client";
import {print} from "../../api/util/Logging";
import {useAppSelector} from "../../api/redux/hooks";
import {selectLoginResponse} from "../../api/redux/slice/SettingSlice";
import {SocketChannel, WebsocketMessage} from "../../proto/framework/websocket/WebsocketMessage";
import {Buffer} from "buffer";
import {useTranslation} from "react-i18next";


const get_clientId = () => {
  return str_strPost("/api/socket/client_id", void 0, void 0, true);
}

const subscriber = new Map<string, (message: WebsocketMessage) => void>();

const WebsocketContext = React.createContext({
  message: void 0 as WebsocketMessage | undefined,
  sendSocketMessage: (message: WebsocketMessage) => {
  },
  subscribeTo: (subscriberId: string, callback: (message: WebsocketMessage) => void) => {
    subscriber.set(subscriberId, callback);
  },
  connected: false
});

const useWebSocketProvider = () => {
  const loginResponse = useAppSelector(selectLoginResponse);

  const [message, setMessage] = useState<WebsocketMessage | undefined>(undefined);
  const [stompClient, setStompClient] = useState<Stomp.Client | null>(null);
  const [clientId, setClientId] = useState<string | null>(null);
  const [connected, setConnected] = useState(false);

  const {t, i18n} = useTranslation();

  const subscribeTo = (subscriberId: string, callback: (message: WebsocketMessage) => void) => {
    // not useful actually
    subscriber.set(subscriberId, callback);
  };

  const sendSocketMessage = useCallback((message: WebsocketMessage, retry = 3) => {
    if (!loginResponse?.userId || retry < 0) {
      return;
    }
    if (!stompClient || !stompClient.connected) {
      setTimeout(() => {
        sendSocketMessage(message, retry - 1);
      }, 1000);
      return;
    }
    if (!stompClient.connected) {
      print("Socket not connected, giving up sending", message);
      return;
    }
    message.from = loginResponse.userId;
    message.timestamp = Date.now();
    stompClient.send("/app/message", {}, Buffer.from(WebsocketMessage.encode(message).finish()).toString('base64'));
  }, [stompClient, loginResponse]);

  const syncSocketLanguage = useCallback(() => {
    const setLanguageMessage = WebsocketMessage.create();
    setLanguageMessage.channel = SocketChannel.SET_LOCALE
    setLanguageMessage.text = i18n.language;
    sendSocketMessage(setLanguageMessage);
  }, [i18n.language, sendSocketMessage]);

  useEffect(() => {
    syncSocketLanguage();
  }, [syncSocketLanguage]);

  // retryTime: if connect failed, retry in retryTime seconds. This is for potential server restart.
  const connectSocket = useCallback((retryTime = 1) => {
    if (!loginResponse || !loginResponse.userId || !clientId) {
      return;
    }

    const socket = new SockJS(apiHost + "/ws?client-id=" + clientId,
        null, {
          timeout: 60000 * 5,
          transports: ["websocket", "xhr-streaming"],  // Exclude 'jsonp-polling'  "xhr-streaming", "websocket"
        });
    const client = Stomp.over(socket);
    client.debug = (msg) => {
      // disable debug messages
      // print(msg);
    }
    client.connect({}, () => {
      client.subscribe("/topic/" + loginResponse.userId, (message) => {
        const recievedMessage = WebsocketMessage.decode(Buffer.from(message.body, 'base64'));
        for (const [, callback] of subscriber) {
          callback(recievedMessage);
        }
        setMessage(recievedMessage);
      },);
      setStompClient(client as Client);
      setConnected(true);
    }, (error) => {
      // renewClientId();
      if (error instanceof Frame) {
        if (error.headers && "message" in error.headers && (typeof error.headers.message === "string")) {
          if (error.headers.message.includes("User not authenticated")) {
            renewClientId();
          }
        }
      }
    });

    socket.onclose = () => {
      setConnected(false);
      if (!loginResponse || !loginResponse.userId) {
        return;
      }
      setTimeout(() => {
        print("Socket disconnected, retrying in " + retryTime + " second");
        connectSocket(retryTime + 1);
      }, retryTime * 1000);
    }

    return () => {
      try {
        socket.onclose = () => {
          print("Socket disconnected.");
        }
        setTimeout(() => {
          socket.close();
          client.disconnect(() => {
          })
        }, 1000);
      } catch (e) {
        print("Error disconnecting: ", e);
      }
    }
  }, [clientId, loginResponse]);

  const renewClientId = useCallback((delayInSecond = 1) => {
    if (!loginResponse?.userId) {
      return;
    }
    get_clientId().then((id) => {
      // print("Got client id: ", id);
      setClientId(id);
    }).catch((e) => {
      print("Error renewing client id: ", e);
      print("Retrying in " + delayInSecond + " second");
      if (loginResponse?.userId) {
        setTimeout(() => renewClientId(delayInSecond + 1), 1000 * delayInSecond);
      }
    });
  }, [loginResponse]);

  useEffect(() => {
        if (!loginResponse?.userId) {
          setClientId(null);
          return;
        }
        renewClientId();
      }, [loginResponse, renewClientId]
  );

  useEffect(() => {
        return connectSocket();
      }, [connectSocket]
  );
  useEffect(() => {
    if (stompClient === null || !stompClient.connected) {
      return;
    }
    syncSocketLanguage();
  }, [stompClient, syncSocketLanguage]);
  return {message, sendSocketMessage, subscribeTo, connected};
}

export {WebsocketContext, useWebSocketProvider};