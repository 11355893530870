// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.2
//   protoc               v5.28.0
// source: mydimension/framework/collector/CollectorMessage.proto

/* eslint-disable */
import {BinaryReader, BinaryWriter} from "@bufbuild/protobuf/wire";
import {ResourceType, resourceTypeFromJSON, resourceTypeToJSON} from "../ResourceMessage";
import {CustomerProfile} from "../customer/CustomerInfo";

export const protobufPackage = "proto.framework.collector";

export enum CollectorType {
  /** COLLECTOR_TYPE_UNKNOWN - unknown type, not set */
  COLLECTOR_TYPE_UNKNOWN = 0,
  /** COLLECTOR_TYPE_LIKE - Describe like behavior */
  COLLECTOR_TYPE_LIKE = 1,
  /** COLLECTOR_TYPE_DISLIKE - Describe dislike behavior */
  COLLECTOR_TYPE_DISLIKE = 2,
  /** COLLECTOR_TYPE_COLLECTION - Describe collection behavior */
  COLLECTOR_TYPE_COLLECTION = 3,
  /** COLLECTOR_TYPE_VIEW - View */
  COLLECTOR_TYPE_VIEW = 4,
  /** COLLECTOR_TYPE_DOWNLOAD - Download */
  COLLECTOR_TYPE_DOWNLOAD = 5,
  /** COLLECTOR_TYPE_FOLLOW - FOLLOW */
  COLLECTOR_TYPE_FOLLOW = 6,
  /** COLLECTOR_TYPE_EVALUATION - Describe evaluation behavior */
  COLLECTOR_TYPE_EVALUATION = 7,
  /** COLLECTOR_TYPE_COMMENT - Describe comment behavior */
  COLLECTOR_TYPE_COMMENT = 8,
  UNRECOGNIZED = -1,
}

export function collectorTypeFromJSON(object: any): CollectorType {
  switch (object) {
    case 0:
    case "COLLECTOR_TYPE_UNKNOWN":
      return CollectorType.COLLECTOR_TYPE_UNKNOWN;
    case 1:
    case "COLLECTOR_TYPE_LIKE":
      return CollectorType.COLLECTOR_TYPE_LIKE;
    case 2:
    case "COLLECTOR_TYPE_DISLIKE":
      return CollectorType.COLLECTOR_TYPE_DISLIKE;
    case 3:
    case "COLLECTOR_TYPE_COLLECTION":
      return CollectorType.COLLECTOR_TYPE_COLLECTION;
    case 4:
    case "COLLECTOR_TYPE_VIEW":
      return CollectorType.COLLECTOR_TYPE_VIEW;
    case 5:
    case "COLLECTOR_TYPE_DOWNLOAD":
      return CollectorType.COLLECTOR_TYPE_DOWNLOAD;
    case 6:
    case "COLLECTOR_TYPE_FOLLOW":
      return CollectorType.COLLECTOR_TYPE_FOLLOW;
    case 7:
    case "COLLECTOR_TYPE_EVALUATION":
      return CollectorType.COLLECTOR_TYPE_EVALUATION;
    case 8:
    case "COLLECTOR_TYPE_COMMENT":
      return CollectorType.COLLECTOR_TYPE_COMMENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CollectorType.UNRECOGNIZED;
  }
}

export function collectorTypeToJSON(object: CollectorType): string {
  switch (object) {
    case CollectorType.COLLECTOR_TYPE_UNKNOWN:
      return "COLLECTOR_TYPE_UNKNOWN";
    case CollectorType.COLLECTOR_TYPE_LIKE:
      return "COLLECTOR_TYPE_LIKE";
    case CollectorType.COLLECTOR_TYPE_DISLIKE:
      return "COLLECTOR_TYPE_DISLIKE";
    case CollectorType.COLLECTOR_TYPE_COLLECTION:
      return "COLLECTOR_TYPE_COLLECTION";
    case CollectorType.COLLECTOR_TYPE_VIEW:
      return "COLLECTOR_TYPE_VIEW";
    case CollectorType.COLLECTOR_TYPE_DOWNLOAD:
      return "COLLECTOR_TYPE_DOWNLOAD";
    case CollectorType.COLLECTOR_TYPE_FOLLOW:
      return "COLLECTOR_TYPE_FOLLOW";
    case CollectorType.COLLECTOR_TYPE_EVALUATION:
      return "COLLECTOR_TYPE_EVALUATION";
    case CollectorType.COLLECTOR_TYPE_COMMENT:
      return "COLLECTOR_TYPE_COMMENT";
    case CollectorType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** 收藏请求 */
export interface CollectorRequest {
  /** 收藏类型 */
  type: CollectorType;
  /** 可选收藏空间id */
  spaceId?:
    | string
    | undefined;
  /** 可选收藏分数 默认为1 */
  score?:
    | number
    | undefined;
  /** 收藏对象的类型 */
  targetType: ResourceType;
  /** 收藏对象的id */
  targetId: string;
}

export interface CollectorProfileQuery {
  targetType: ResourceType;
  targetIds: string[];
}

export interface CollectorProfileResponse {
  profiles: CollectorProfile[];
}

export interface CollectorProfile {
  targetType: ResourceType;
  /** 收藏对象的id */
  targetId: string;
  targetProfile?: CollectorTargetProfile | undefined;
  userCollectState?: UserCollectState | undefined;
}

/** 被收藏对象的收藏统计信息 */
export interface CollectorTargetProfile {
  /** 收藏对象的类型 */
  targetType: ResourceType;
  /** 收藏对象的id */
  targetId: string;
  profiles: CollectorTargetProfile_Profile[];
}

/** 每种收藏类型的统计信息 */
export interface CollectorTargetProfile_Profile {
  /** 收藏类型 */
  type: CollectorType;
  /** 收藏数量 以用户为单位 */
  count: number;
  /** 平均分数 */
  score?: number | undefined;
}

/** 用户对某个对象的收藏状态 */
export interface UserCollectState {
  /** 用户id */
  customerId: string;
  /** 收藏对象的类型 */
  targetType: ResourceType;
  /** 收藏对象的id */
  targetId: string;
  /** 用户对该对象的所有收藏状态 没有则为空 */
  collectStates: UserCollectState_CollectState[];
}

export interface UserCollectState_CollectState {
  /** 用户收藏的对象 */
  type: CollectorType;
  spaceProfiles?: CollectorSpaceProfile | undefined;
  score?: number | undefined;
}

export interface CollectorSpaceProfile {
  id: string;
  name: string;
  description?: string | undefined;
  avatarUrl?: string | undefined;
  isPublic: boolean;
  createTime: number;
  creator: CustomerProfile | undefined;
}

function createBaseCollectorRequest(): CollectorRequest {
  return { type: 0, spaceId: undefined, score: undefined, targetType: 0, targetId: "" };
}

export const CollectorRequest = {
  encode(message: CollectorRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.spaceId !== undefined) {
      writer.uint32(18).string(message.spaceId);
    }
    if (message.score !== undefined) {
      writer.uint32(25).double(message.score);
    }
    if (message.targetType !== 0) {
      writer.uint32(32).int32(message.targetType);
    }
    if (message.targetId !== "") {
      writer.uint32(42).string(message.targetId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CollectorRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCollectorRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.spaceId = reader.string();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.score = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.targetType = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.targetId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CollectorRequest {
    return {
      type: isSet(object.type) ? collectorTypeFromJSON(object.type) : 0,
      spaceId: isSet(object.spaceId) ? globalThis.String(object.spaceId) : undefined,
      score: isSet(object.score) ? globalThis.Number(object.score) : undefined,
      targetType: isSet(object.targetType) ? resourceTypeFromJSON(object.targetType) : 0,
      targetId: isSet(object.targetId) ? globalThis.String(object.targetId) : "",
    };
  },

  toJSON(message: CollectorRequest): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = collectorTypeToJSON(message.type);
    }
    if (message.spaceId !== undefined) {
      obj.spaceId = message.spaceId;
    }
    if (message.score !== undefined) {
      obj.score = message.score;
    }
    if (message.targetType !== 0) {
      obj.targetType = resourceTypeToJSON(message.targetType);
    }
    if (message.targetId !== "") {
      obj.targetId = message.targetId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CollectorRequest>, I>>(base?: I): CollectorRequest {
    return CollectorRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CollectorRequest>, I>>(object: I): CollectorRequest {
    const message = createBaseCollectorRequest();
    message.type = object.type ?? 0;
    message.spaceId = object.spaceId ?? undefined;
    message.score = object.score ?? undefined;
    message.targetType = object.targetType ?? 0;
    message.targetId = object.targetId ?? "";
    return message;
  },
};

function createBaseCollectorProfileQuery(): CollectorProfileQuery {
  return { targetType: 0, targetIds: [] };
}

export const CollectorProfileQuery = {
  encode(message: CollectorProfileQuery, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.targetType !== 0) {
      writer.uint32(8).int32(message.targetType);
    }
    for (const v of message.targetIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CollectorProfileQuery {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCollectorProfileQuery();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.targetType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.targetIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CollectorProfileQuery {
    return {
      targetType: isSet(object.targetType) ? resourceTypeFromJSON(object.targetType) : 0,
      targetIds: globalThis.Array.isArray(object?.targetIds)
        ? object.targetIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: CollectorProfileQuery): unknown {
    const obj: any = {};
    if (message.targetType !== 0) {
      obj.targetType = resourceTypeToJSON(message.targetType);
    }
    if (message.targetIds?.length) {
      obj.targetIds = message.targetIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CollectorProfileQuery>, I>>(base?: I): CollectorProfileQuery {
    return CollectorProfileQuery.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CollectorProfileQuery>, I>>(object: I): CollectorProfileQuery {
    const message = createBaseCollectorProfileQuery();
    message.targetType = object.targetType ?? 0;
    message.targetIds = object.targetIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseCollectorProfileResponse(): CollectorProfileResponse {
  return { profiles: [] };
}

export const CollectorProfileResponse = {
  encode(message: CollectorProfileResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.profiles) {
      CollectorProfile.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CollectorProfileResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCollectorProfileResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.profiles.push(CollectorProfile.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CollectorProfileResponse {
    return {
      profiles: globalThis.Array.isArray(object?.profiles)
        ? object.profiles.map((e: any) => CollectorProfile.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CollectorProfileResponse): unknown {
    const obj: any = {};
    if (message.profiles?.length) {
      obj.profiles = message.profiles.map((e) => CollectorProfile.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CollectorProfileResponse>, I>>(base?: I): CollectorProfileResponse {
    return CollectorProfileResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CollectorProfileResponse>, I>>(object: I): CollectorProfileResponse {
    const message = createBaseCollectorProfileResponse();
    message.profiles = object.profiles?.map((e) => CollectorProfile.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCollectorProfile(): CollectorProfile {
  return { targetType: 0, targetId: "", targetProfile: undefined, userCollectState: undefined };
}

export const CollectorProfile = {
  encode(message: CollectorProfile, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.targetType !== 0) {
      writer.uint32(8).int32(message.targetType);
    }
    if (message.targetId !== "") {
      writer.uint32(18).string(message.targetId);
    }
    if (message.targetProfile !== undefined) {
      CollectorTargetProfile.encode(message.targetProfile, writer.uint32(26).fork()).join();
    }
    if (message.userCollectState !== undefined) {
      UserCollectState.encode(message.userCollectState, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CollectorProfile {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCollectorProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.targetType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.targetId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.targetProfile = CollectorTargetProfile.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.userCollectState = UserCollectState.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CollectorProfile {
    return {
      targetType: isSet(object.targetType) ? resourceTypeFromJSON(object.targetType) : 0,
      targetId: isSet(object.targetId) ? globalThis.String(object.targetId) : "",
      targetProfile: isSet(object.targetProfile) ? CollectorTargetProfile.fromJSON(object.targetProfile) : undefined,
      userCollectState: isSet(object.userCollectState) ? UserCollectState.fromJSON(object.userCollectState) : undefined,
    };
  },

  toJSON(message: CollectorProfile): unknown {
    const obj: any = {};
    if (message.targetType !== 0) {
      obj.targetType = resourceTypeToJSON(message.targetType);
    }
    if (message.targetId !== "") {
      obj.targetId = message.targetId;
    }
    if (message.targetProfile !== undefined) {
      obj.targetProfile = CollectorTargetProfile.toJSON(message.targetProfile);
    }
    if (message.userCollectState !== undefined) {
      obj.userCollectState = UserCollectState.toJSON(message.userCollectState);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CollectorProfile>, I>>(base?: I): CollectorProfile {
    return CollectorProfile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CollectorProfile>, I>>(object: I): CollectorProfile {
    const message = createBaseCollectorProfile();
    message.targetType = object.targetType ?? 0;
    message.targetId = object.targetId ?? "";
    message.targetProfile = (object.targetProfile !== undefined && object.targetProfile !== null)
      ? CollectorTargetProfile.fromPartial(object.targetProfile)
      : undefined;
    message.userCollectState = (object.userCollectState !== undefined && object.userCollectState !== null)
      ? UserCollectState.fromPartial(object.userCollectState)
      : undefined;
    return message;
  },
};

function createBaseCollectorTargetProfile(): CollectorTargetProfile {
  return { targetType: 0, targetId: "", profiles: [] };
}

export const CollectorTargetProfile = {
  encode(message: CollectorTargetProfile, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.targetType !== 0) {
      writer.uint32(8).int32(message.targetType);
    }
    if (message.targetId !== "") {
      writer.uint32(18).string(message.targetId);
    }
    for (const v of message.profiles) {
      CollectorTargetProfile_Profile.encode(v!, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CollectorTargetProfile {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCollectorTargetProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.targetType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.targetId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.profiles.push(CollectorTargetProfile_Profile.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CollectorTargetProfile {
    return {
      targetType: isSet(object.targetType) ? resourceTypeFromJSON(object.targetType) : 0,
      targetId: isSet(object.targetId) ? globalThis.String(object.targetId) : "",
      profiles: globalThis.Array.isArray(object?.profiles)
        ? object.profiles.map((e: any) => CollectorTargetProfile_Profile.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CollectorTargetProfile): unknown {
    const obj: any = {};
    if (message.targetType !== 0) {
      obj.targetType = resourceTypeToJSON(message.targetType);
    }
    if (message.targetId !== "") {
      obj.targetId = message.targetId;
    }
    if (message.profiles?.length) {
      obj.profiles = message.profiles.map((e) => CollectorTargetProfile_Profile.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CollectorTargetProfile>, I>>(base?: I): CollectorTargetProfile {
    return CollectorTargetProfile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CollectorTargetProfile>, I>>(object: I): CollectorTargetProfile {
    const message = createBaseCollectorTargetProfile();
    message.targetType = object.targetType ?? 0;
    message.targetId = object.targetId ?? "";
    message.profiles = object.profiles?.map((e) => CollectorTargetProfile_Profile.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCollectorTargetProfile_Profile(): CollectorTargetProfile_Profile {
  return { type: 0, count: 0, score: undefined };
}

export const CollectorTargetProfile_Profile = {
  encode(message: CollectorTargetProfile_Profile, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.count !== 0) {
      writer.uint32(16).int32(message.count);
    }
    if (message.score !== undefined) {
      writer.uint32(25).double(message.score);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CollectorTargetProfile_Profile {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCollectorTargetProfile_Profile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.count = reader.int32();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.score = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CollectorTargetProfile_Profile {
    return {
      type: isSet(object.type) ? collectorTypeFromJSON(object.type) : 0,
      count: isSet(object.count) ? globalThis.Number(object.count) : 0,
      score: isSet(object.score) ? globalThis.Number(object.score) : undefined,
    };
  },

  toJSON(message: CollectorTargetProfile_Profile): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = collectorTypeToJSON(message.type);
    }
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    if (message.score !== undefined) {
      obj.score = message.score;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CollectorTargetProfile_Profile>, I>>(base?: I): CollectorTargetProfile_Profile {
    return CollectorTargetProfile_Profile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CollectorTargetProfile_Profile>, I>>(
    object: I,
  ): CollectorTargetProfile_Profile {
    const message = createBaseCollectorTargetProfile_Profile();
    message.type = object.type ?? 0;
    message.count = object.count ?? 0;
    message.score = object.score ?? undefined;
    return message;
  },
};

function createBaseUserCollectState(): UserCollectState {
  return { customerId: "", targetType: 0, targetId: "", collectStates: [] };
}

export const UserCollectState = {
  encode(message: UserCollectState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.customerId !== "") {
      writer.uint32(10).string(message.customerId);
    }
    if (message.targetType !== 0) {
      writer.uint32(16).int32(message.targetType);
    }
    if (message.targetId !== "") {
      writer.uint32(26).string(message.targetId);
    }
    for (const v of message.collectStates) {
      UserCollectState_CollectState.encode(v!, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UserCollectState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserCollectState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.targetType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.targetId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.collectStates.push(UserCollectState_CollectState.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserCollectState {
    return {
      customerId: isSet(object.customerId) ? globalThis.String(object.customerId) : "",
      targetType: isSet(object.targetType) ? resourceTypeFromJSON(object.targetType) : 0,
      targetId: isSet(object.targetId) ? globalThis.String(object.targetId) : "",
      collectStates: globalThis.Array.isArray(object?.collectStates)
        ? object.collectStates.map((e: any) => UserCollectState_CollectState.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UserCollectState): unknown {
    const obj: any = {};
    if (message.customerId !== "") {
      obj.customerId = message.customerId;
    }
    if (message.targetType !== 0) {
      obj.targetType = resourceTypeToJSON(message.targetType);
    }
    if (message.targetId !== "") {
      obj.targetId = message.targetId;
    }
    if (message.collectStates?.length) {
      obj.collectStates = message.collectStates.map((e) => UserCollectState_CollectState.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserCollectState>, I>>(base?: I): UserCollectState {
    return UserCollectState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserCollectState>, I>>(object: I): UserCollectState {
    const message = createBaseUserCollectState();
    message.customerId = object.customerId ?? "";
    message.targetType = object.targetType ?? 0;
    message.targetId = object.targetId ?? "";
    message.collectStates = object.collectStates?.map((e) => UserCollectState_CollectState.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserCollectState_CollectState(): UserCollectState_CollectState {
  return { type: 0, spaceProfiles: undefined, score: undefined };
}

export const UserCollectState_CollectState = {
  encode(message: UserCollectState_CollectState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.spaceProfiles !== undefined) {
      CollectorSpaceProfile.encode(message.spaceProfiles, writer.uint32(18).fork()).join();
    }
    if (message.score !== undefined) {
      writer.uint32(25).double(message.score);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UserCollectState_CollectState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserCollectState_CollectState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.spaceProfiles = CollectorSpaceProfile.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.score = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserCollectState_CollectState {
    return {
      type: isSet(object.type) ? collectorTypeFromJSON(object.type) : 0,
      spaceProfiles: isSet(object.spaceProfiles) ? CollectorSpaceProfile.fromJSON(object.spaceProfiles) : undefined,
      score: isSet(object.score) ? globalThis.Number(object.score) : undefined,
    };
  },

  toJSON(message: UserCollectState_CollectState): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = collectorTypeToJSON(message.type);
    }
    if (message.spaceProfiles !== undefined) {
      obj.spaceProfiles = CollectorSpaceProfile.toJSON(message.spaceProfiles);
    }
    if (message.score !== undefined) {
      obj.score = message.score;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserCollectState_CollectState>, I>>(base?: I): UserCollectState_CollectState {
    return UserCollectState_CollectState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserCollectState_CollectState>, I>>(
    object: I,
  ): UserCollectState_CollectState {
    const message = createBaseUserCollectState_CollectState();
    message.type = object.type ?? 0;
    message.spaceProfiles = (object.spaceProfiles !== undefined && object.spaceProfiles !== null)
      ? CollectorSpaceProfile.fromPartial(object.spaceProfiles)
      : undefined;
    message.score = object.score ?? undefined;
    return message;
  },
};

function createBaseCollectorSpaceProfile(): CollectorSpaceProfile {
  return {
    id: "",
    name: "",
    description: undefined,
    avatarUrl: undefined,
    isPublic: false,
    createTime: 0,
    creator: undefined,
  };
}

export const CollectorSpaceProfile = {
  encode(message: CollectorSpaceProfile, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== undefined) {
      writer.uint32(26).string(message.description);
    }
    if (message.avatarUrl !== undefined) {
      writer.uint32(34).string(message.avatarUrl);
    }
    if (message.isPublic !== false) {
      writer.uint32(40).bool(message.isPublic);
    }
    if (message.createTime !== 0) {
      writer.uint32(48).int64(message.createTime);
    }
    if (message.creator !== undefined) {
      CustomerProfile.encode(message.creator, writer.uint32(58).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CollectorSpaceProfile {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCollectorSpaceProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.avatarUrl = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isPublic = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.createTime = longToNumber(reader.int64());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.creator = CustomerProfile.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CollectorSpaceProfile {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : undefined,
      avatarUrl: isSet(object.avatarUrl) ? globalThis.String(object.avatarUrl) : undefined,
      isPublic: isSet(object.isPublic) ? globalThis.Boolean(object.isPublic) : false,
      createTime: isSet(object.createTime) ? globalThis.Number(object.createTime) : 0,
      creator: isSet(object.creator) ? CustomerProfile.fromJSON(object.creator) : undefined,
    };
  },

  toJSON(message: CollectorSpaceProfile): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== undefined) {
      obj.description = message.description;
    }
    if (message.avatarUrl !== undefined) {
      obj.avatarUrl = message.avatarUrl;
    }
    if (message.isPublic !== false) {
      obj.isPublic = message.isPublic;
    }
    if (message.createTime !== 0) {
      obj.createTime = Math.round(message.createTime);
    }
    if (message.creator !== undefined) {
      obj.creator = CustomerProfile.toJSON(message.creator);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CollectorSpaceProfile>, I>>(base?: I): CollectorSpaceProfile {
    return CollectorSpaceProfile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CollectorSpaceProfile>, I>>(object: I): CollectorSpaceProfile {
    const message = createBaseCollectorSpaceProfile();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? undefined;
    message.avatarUrl = object.avatarUrl ?? undefined;
    message.isPublic = object.isPublic ?? false;
    message.createTime = object.createTime ?? 0;
    message.creator = (object.creator !== undefined && object.creator !== null)
      ? CustomerProfile.fromPartial(object.creator)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
