// a hook to record the view of a resource, the view will be recorded after timeInSeconds
import {ResourceType} from "../../proto/framework/ResourceMessage";
import {useAppSelector} from "../../api/redux/hooks";
import {selectLoginResponse} from "../../api/redux/slice/SettingSlice";
import {collect} from "./CollectorService";
import {CollectorType} from "../../proto/framework/collector/CollectorMessage";
import {useCallback, useEffect} from "react";
import {print} from "../../api/util/Logging";

export const useViewRecorder = (resourceType: ResourceType, resourceId: string, timeInSeconds = 5) => {
  const loginResponse = useAppSelector(selectLoginResponse);
  const hasLogin = loginResponse?.userId && loginResponse.userId.length > 0;

  const recordView = useCallback(() => {
    if (hasLogin) {
      // record view
      void collect(CollectorType.COLLECTOR_TYPE_VIEW, resourceType, resourceId).catch((e) => {
        print(e);
      });
    }
  }, [hasLogin, resourceType, resourceId]);

  useEffect(() => {
    const view = setTimeout(() => {
      recordView();
    }, timeInSeconds * 1000);

    return () => clearTimeout(view);
  }, [recordView, timeInSeconds]);
}