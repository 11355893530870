// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.2
//   protoc               v5.28.0
// source: mydimension/framework/notification/NotificationMessage.proto

/* eslint-disable */
import {BinaryReader, BinaryWriter} from "@bufbuild/protobuf/wire";
import {PageQuery, PageResult} from "../QueryMessage";
import {CustomerProfile} from "../customer/CustomerInfo";

export const protobufPackage = "proto.framework.notification";

/** 通知消息类型 应当安装展示方式进行分类 而非业务分类 */
export enum NotificationType {
  DEFAULT = 0,
  UNRECOGNIZED = -1,
}

export function notificationTypeFromJSON(object: any): NotificationType {
  switch (object) {
    case 0:
    case "DEFAULT":
      return NotificationType.DEFAULT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return NotificationType.UNRECOGNIZED;
  }
}

export function notificationTypeToJSON(object: NotificationType): string {
  switch (object) {
    case NotificationType.DEFAULT:
      return "DEFAULT";
    case NotificationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface NotificationProfile {
  id: string;
  sender?: CustomerProfile | undefined;
  title: string;
  content: string;
  avatarUrl?: string | undefined;
  link?: string | undefined;
  type: NotificationType;
  read: boolean;
  createAt: number;
  count: number;
}

export interface NotificationQuery {
  /** 分页查询 */
  pageQuery?: PageQuery | undefined;
  unreadOnly: boolean;
}

export interface NotificationAckRequest {
  notificationIds: string[];
  delete: boolean;
}

export interface NotificationResponse {
  /** sorted by create_at desc, no paging */
  notifications: NotificationProfile[];
  pageResult?: PageResult | undefined;
}

function createBaseNotificationProfile(): NotificationProfile {
  return {
    id: "",
    sender: undefined,
    title: "",
    content: "",
    avatarUrl: undefined,
    link: undefined,
    type: 0,
    read: false,
    createAt: 0,
    count: 0,
  };
}

export const NotificationProfile = {
  encode(message: NotificationProfile, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.sender !== undefined) {
      CustomerProfile.encode(message.sender, writer.uint32(18).fork()).join();
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.content !== "") {
      writer.uint32(34).string(message.content);
    }
    if (message.avatarUrl !== undefined) {
      writer.uint32(42).string(message.avatarUrl);
    }
    if (message.link !== undefined) {
      writer.uint32(50).string(message.link);
    }
    if (message.type !== 0) {
      writer.uint32(56).int32(message.type);
    }
    if (message.read !== false) {
      writer.uint32(64).bool(message.read);
    }
    if (message.createAt !== 0) {
      writer.uint32(72).int64(message.createAt);
    }
    if (message.count !== 0) {
      writer.uint32(80).int32(message.count);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): NotificationProfile {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sender = CustomerProfile.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.content = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.avatarUrl = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.link = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.read = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.createAt = longToNumber(reader.int64());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.count = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NotificationProfile {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      sender: isSet(object.sender) ? CustomerProfile.fromJSON(object.sender) : undefined,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      content: isSet(object.content) ? globalThis.String(object.content) : "",
      avatarUrl: isSet(object.avatarUrl) ? globalThis.String(object.avatarUrl) : undefined,
      link: isSet(object.link) ? globalThis.String(object.link) : undefined,
      type: isSet(object.type) ? notificationTypeFromJSON(object.type) : 0,
      read: isSet(object.read) ? globalThis.Boolean(object.read) : false,
      createAt: isSet(object.createAt) ? globalThis.Number(object.createAt) : 0,
      count: isSet(object.count) ? globalThis.Number(object.count) : 0,
    };
  },

  toJSON(message: NotificationProfile): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.sender !== undefined) {
      obj.sender = CustomerProfile.toJSON(message.sender);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.content !== "") {
      obj.content = message.content;
    }
    if (message.avatarUrl !== undefined) {
      obj.avatarUrl = message.avatarUrl;
    }
    if (message.link !== undefined) {
      obj.link = message.link;
    }
    if (message.type !== 0) {
      obj.type = notificationTypeToJSON(message.type);
    }
    if (message.read !== false) {
      obj.read = message.read;
    }
    if (message.createAt !== 0) {
      obj.createAt = Math.round(message.createAt);
    }
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NotificationProfile>, I>>(base?: I): NotificationProfile {
    return NotificationProfile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NotificationProfile>, I>>(object: I): NotificationProfile {
    const message = createBaseNotificationProfile();
    message.id = object.id ?? "";
    message.sender = (object.sender !== undefined && object.sender !== null)
      ? CustomerProfile.fromPartial(object.sender)
      : undefined;
    message.title = object.title ?? "";
    message.content = object.content ?? "";
    message.avatarUrl = object.avatarUrl ?? undefined;
    message.link = object.link ?? undefined;
    message.type = object.type ?? 0;
    message.read = object.read ?? false;
    message.createAt = object.createAt ?? 0;
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseNotificationQuery(): NotificationQuery {
  return { pageQuery: undefined, unreadOnly: false };
}

export const NotificationQuery = {
  encode(message: NotificationQuery, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageQuery !== undefined) {
      PageQuery.encode(message.pageQuery, writer.uint32(10).fork()).join();
    }
    if (message.unreadOnly !== false) {
      writer.uint32(16).bool(message.unreadOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): NotificationQuery {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationQuery();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pageQuery = PageQuery.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.unreadOnly = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NotificationQuery {
    return {
      pageQuery: isSet(object.pageQuery) ? PageQuery.fromJSON(object.pageQuery) : undefined,
      unreadOnly: isSet(object.unreadOnly) ? globalThis.Boolean(object.unreadOnly) : false,
    };
  },

  toJSON(message: NotificationQuery): unknown {
    const obj: any = {};
    if (message.pageQuery !== undefined) {
      obj.pageQuery = PageQuery.toJSON(message.pageQuery);
    }
    if (message.unreadOnly !== false) {
      obj.unreadOnly = message.unreadOnly;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NotificationQuery>, I>>(base?: I): NotificationQuery {
    return NotificationQuery.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NotificationQuery>, I>>(object: I): NotificationQuery {
    const message = createBaseNotificationQuery();
    message.pageQuery = (object.pageQuery !== undefined && object.pageQuery !== null)
      ? PageQuery.fromPartial(object.pageQuery)
      : undefined;
    message.unreadOnly = object.unreadOnly ?? false;
    return message;
  },
};

function createBaseNotificationAckRequest(): NotificationAckRequest {
  return { notificationIds: [], delete: false };
}

export const NotificationAckRequest = {
  encode(message: NotificationAckRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.notificationIds) {
      writer.uint32(10).string(v!);
    }
    if (message.delete !== false) {
      writer.uint32(16).bool(message.delete);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): NotificationAckRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationAckRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.notificationIds.push(reader.string());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.delete = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NotificationAckRequest {
    return {
      notificationIds: globalThis.Array.isArray(object?.notificationIds)
        ? object.notificationIds.map((e: any) => globalThis.String(e))
        : [],
      delete: isSet(object.delete) ? globalThis.Boolean(object.delete) : false,
    };
  },

  toJSON(message: NotificationAckRequest): unknown {
    const obj: any = {};
    if (message.notificationIds?.length) {
      obj.notificationIds = message.notificationIds;
    }
    if (message.delete !== false) {
      obj.delete = message.delete;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NotificationAckRequest>, I>>(base?: I): NotificationAckRequest {
    return NotificationAckRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NotificationAckRequest>, I>>(object: I): NotificationAckRequest {
    const message = createBaseNotificationAckRequest();
    message.notificationIds = object.notificationIds?.map((e) => e) || [];
    message.delete = object.delete ?? false;
    return message;
  },
};

function createBaseNotificationResponse(): NotificationResponse {
  return { notifications: [], pageResult: undefined };
}

export const NotificationResponse = {
  encode(message: NotificationResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.notifications) {
      NotificationProfile.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.pageResult !== undefined) {
      PageResult.encode(message.pageResult, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): NotificationResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.notifications.push(NotificationProfile.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pageResult = PageResult.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NotificationResponse {
    return {
      notifications: globalThis.Array.isArray(object?.notifications)
        ? object.notifications.map((e: any) => NotificationProfile.fromJSON(e))
        : [],
      pageResult: isSet(object.pageResult) ? PageResult.fromJSON(object.pageResult) : undefined,
    };
  },

  toJSON(message: NotificationResponse): unknown {
    const obj: any = {};
    if (message.notifications?.length) {
      obj.notifications = message.notifications.map((e) => NotificationProfile.toJSON(e));
    }
    if (message.pageResult !== undefined) {
      obj.pageResult = PageResult.toJSON(message.pageResult);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NotificationResponse>, I>>(base?: I): NotificationResponse {
    return NotificationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NotificationResponse>, I>>(object: I): NotificationResponse {
    const message = createBaseNotificationResponse();
    message.notifications = object.notifications?.map((e) => NotificationProfile.fromPartial(e)) || [];
    message.pageResult = (object.pageResult !== undefined && object.pageResult !== null)
      ? PageResult.fromPartial(object.pageResult)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
