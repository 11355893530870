import {CloseDialogCallback, DialogName} from "../component/dialog/DialogManager";
import {useAppSelector} from "../api/redux/hooks";
import {selectDialogName, selectOnDialogClose} from "../api/redux/slice/DialogSlice";
import React, {DependencyList, useEffect, useRef} from "react";
import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {selectLoginResponse} from "../api/redux/slice/SettingSlice";
import {UserRole} from "../proto/framework/SecurityMessage";
import {useNavigate} from "react-router-dom";
import {openExternalLink} from "../api/util/constants";

// 返回一个函数，用于关闭对话框并执行回调函数保存对话框的执行结果
// success: 对话框执行结果是否成功
// data: 对话框执行结果的数据 可以是任意类型
// 利用 openDialogWithPromise 可以在對話框關閉時獲取到這些結果
export const useHandleDialogClose = <T = any>(name: DialogName, onDialogClose?: CloseDialogCallback) => {
  const dialogName = useAppSelector(selectDialogName);
  const dialogClose = useAppSelector(selectOnDialogClose);

  return (success: boolean, data?: T) => {
    window.history.pushState(
        'forward',
        '',
        window.location.pathname + window.location.search
    );

    if (onDialogClose) {
      onDialogClose(success, data);
    }
    if (dialogName === name) {
      dialogClose(success, data);
    }
  }
}

export const useAutoFocus = (deps?: DependencyList, actions?: (inputRef: React.MutableRefObject<any>) => void, delay = 100) => {
  const inputRef = useRef<null | HTMLElement>(null);
  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
      if (actions) {
        actions(inputRef);
      }
    }, delay);
  }, deps)
  return inputRef;
}

export const useFieldValidation = (validator: (value: string) => string | undefined, initialValue = ""): [string, ((value: (((prevState: string) => string) | string)) => void), (string | undefined), ((value: (((prevState: (string | undefined)) => (string | undefined)) | string | undefined)) => void), (() => boolean)] => {
  const [fieldValue, setFieldValue] = React.useState<string>(initialValue);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(void 0);
  const validate = () => {
    setErrorMessage(void 0);
    const error = validator(fieldValue);
    const valid = error === void 0 || error === null || error === "";
    if (!valid) {
      setErrorMessage(error);
    }
    return valid;
  }

  return [fieldValue, setFieldValue, errorMessage, setErrorMessage, validate];
}

export const useFieldsValidation = (validators: ((value: string) => string | undefined)[], postValidator: (values: string[]) => string | undefined, initialValues?: string[]) => {
  const all: [string, ((value: (((prevState: string) => string) | string)) => void), (string | undefined), ((value: (((prevState: (string | undefined)) => (string | undefined)) | string | undefined)) => void), (() => boolean)][] = []

  for (const validator of validators) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fieldValue, setFieldValue, errorMessage, setErrorMessage, validate] = useFieldValidation(validator, initialValues?.shift());
    all.push([fieldValue, setFieldValue, errorMessage, setErrorMessage, validate]);
  }

  const [fieldsErrorMessage, setFieldsErrorMessage] = React.useState<string | undefined>(void 0);
  const validate = () => {
    for (const [, , , setErrorMessage,] of all) {
      setErrorMessage(void 0);
    }
    setFieldsErrorMessage(void 0);

    let valid = true;
    for (const [, , , , validate] of all) {
      valid = valid && validate();
    }

    if (!valid) {
      return valid;
    }

    const fieldsError = postValidator(all.map(value => value[0]));
    valid = (fieldsError === void 0 || fieldsError === null || fieldsError === "");
    if (!valid) {
      setFieldsErrorMessage(fieldsError);
    }
    return valid;
  }

  // const result: Record<string, any> = {};
  //
  // for (let i = 0; i < all.length; i++) {
  //   result[`fieldValue${i}`] = all[i][0];
  //   result[`setFieldValue${i}`] = all[i][1];
  //   result[`errorMessage${i}`] = all[i][2];
  // }
  //
  // result["fieldsErrorMessage"] = fieldsErrorMessage;
  // result["setFieldsErrorMessage"] = setFieldsErrorMessage;
  // result["validate"] = validate;
  // return result;

  const result: Array<any> = [];

  for (const value of all) {
    result.push(value[0]);
    result.push(value[1]);
    result.push(value[2]);
  }

  result.push(fieldsErrorMessage);
  result.push(setFieldsErrorMessage);
  result.push(validate);
  return result;
}

export const useNonEmptyField = (errorMessage: string) => {
  return useFieldValidation((value) => {
    if (value.length === 0) {
      return errorMessage;
    }
  });
}

export const useNonEmptyFields = (errorMessages: string[]) => {
  return useFieldsValidation(errorMessages.map(errorMessage => (value) => {
    if (value.length === 0) {
      return errorMessage;
    }
  }), () => void 0);
}

export const useCaptchaCode = () => {
  return useNonEmptyField("Captcha Code is empty");
}

export const useIsSmallScreen = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("md"));
}

export const useIsUser = (userId?: string) => {
  const loginResponse = useAppSelector(selectLoginResponse);
  if (!userId) {
    return false;
  }
  return loginResponse?.userId === userId;
}

export const useIsUserOrAdmin = (userId?: string) => {
  const loginResponse = useAppSelector(selectLoginResponse);
  if (!userId) {
    return loginResponse?.role === UserRole.ADMIN;
  }
  return loginResponse?.userId === userId || loginResponse?.role === UserRole.ADMIN;
}

export const useIsAdmin = () => {
  const loginResponse = useAppSelector(selectLoginResponse);
  return loginResponse?.role === UserRole.ADMIN;
}

export const useSmartNavigate = () => {
  const navigateTo = useNavigate();
  return (url: string) => {
    if (url.startsWith("http")) {
      openExternalLink(url);
    } else {
      navigateTo(url);
    }
  };
}