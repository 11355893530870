import React from "react";
import DimensionDialog from "../../../component/dialog/DimensionDialog";
import {CloseableDialogProps, openDialog} from "../../../component/dialog/DialogManager";
import {useAutoFocus, useFieldsValidation, useHandleDialogClose} from "../../../hook/CommonUiHook";
import {validateEmail, validateUsername} from "../../../api/util/Validation";
import Divider from "@mui/material/Divider";
import LockIcon from '@mui/icons-material/Lock';
import DimensionForm from "../../../component/container/DimensionForm";
import ClearableTextField from "../../../component/input/ClearableTextField";
import PasswordTextField from "../../../component/input/PasswordTextField";
import {
  getGithubOAuthUrl,
  requestEmailOtp,
  SIGN_UP_RECAPTCHA_ACTION,
  signIn
} from "../service/AuthenticationService";
import {AuthenticationRequest, AuthenticationType} from "../../../proto/framework/SecurityMessage";
import {ExceptionResponse} from "../../../proto/framework/ExceptionMessage";
import {FormControlLabel} from "../../../component/wrapper/MuiWrappers";
import MailLockTwoToneIcon from '@mui/icons-material/MailLockTwoTone';
import {Checkbox, Stack} from "@mui/material";
import IconTextButton from "../../../component/button/IconTextButton";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {print} from "../../../api/util/Logging";
import {useGoogleLogin} from '@react-oauth/google';
import AlertContext from "../../../context/AlertContext";
import GoogleIcon from '@mui/icons-material/Google';
import GitHubIcon from '@mui/icons-material/GitHub';

const SignInDialog = ({open, onDialogClose = void 0, sx, ...rest}: CloseableDialogProps) => {

  const handleClose = useHandleDialogClose("SignInDialog", onDialogClose);
  const inputRef = useAutoFocus([open]);
  const {showAlert} = React.useContext(AlertContext);
  const [rememberMe, setRememberMe] = React.useState(true);
  const [infoMessage, setInfoMessage] = React.useState<string|undefined>(void 0);
  const [authenticationType, setAuthenticationType] = React.useState(AuthenticationType.PASSWORD);

  const {executeRecaptcha} = useGoogleReCaptcha();
  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
      const request = AuthenticationRequest.create();
      request.authenticationType = AuthenticationType.GOOGLE_OAUTH;
      request.userIdentifier = "Google User"; // unused
      request.credential = tokenResponse.access_token;
      request.rememberMe = rememberMe;
      return signIn(request).then(() => {
        handleClose(true);
      }).catch((error) => {
        setFieldsErrorMessage(error.message);
      });
    },
    onError: error => showAlert("Google Login is currently not Available")
  });

  const githubLogin = () => {
    return getGithubOAuthUrl(rememberMe).then(url => {
      window.location.href = url;
    });
    // window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientID}&redirect_uri=${redirectURI}`;
  }

  const
      [username, setUsername, usernameErrorMessage,
        password, setPassword, passwordErrorMessage,
        fieldsErrorMessage, setFieldsErrorMessage, validate] = useFieldsValidation([
            (username) => {
              if (username.length === 0) {
                return "Username cannot be empty";
              }
              if (!validateUsername(username)) {
                return "Username does not exist";
              }
            },
            (password) => {
              if (password.length === 0) {
                return "Password cannot be empty";
              }

            }],
          () => void 0);

  //
  // useEffect(() => {
  //   setUsername("");
  //   setPassword("");
  //   setAuthenticationType(AuthenticationType.PASSWORD);
  // }, [open]);

  const onSubmit = () => {
    if (!validate()) {
      return;
    }
    const request = AuthenticationRequest.create();
    request.authenticationType = authenticationType;
    request.userIdentifier = username;
    request.credential = password;
    request.rememberMe = rememberMe;
    return signIn(request).then(() => {
      handleClose(true);
    }).catch((error: ExceptionResponse) => {
      setFieldsErrorMessage("Wrong username or password");
      print("error: " + error);
    });
  }

  const onRequestEmailOtp = async () => {
    if (!validateEmail(username)) {
      setFieldsErrorMessage("Invalid Email Format");
      return;
    }

    if (!executeRecaptcha) {
      print('Execute recaptcha not yet available');
      setFieldsErrorMessage("Something is wrong with email login, Please try another login method.");
      // use traditional captcha handling / skip
      return;
    }

    // https://cloud.google.com/recaptcha/docs/actions-website
    const token = await executeRecaptcha(SIGN_UP_RECAPTCHA_ACTION);

    requestEmailOtp(username, token).then(() => {
      setInfoMessage("OTP has been sent to your email, please check it.");
      setAuthenticationType(AuthenticationType.EMAIL_OTP);
    }).catch(error => {
      setFieldsErrorMessage(error.message);
    })

  }

  const getPasswordLabel = () => {
    switch (authenticationType) {
      case AuthenticationType.EMAIL_OTP:
        return "Email OTP";
      case AuthenticationType.SMS_OTP:
        return "SMS OTP";
      default:
        return "Password";
    }
  }

  return <DimensionDialog
      dialogTitle={"Sign In"}
      dialogStartNode={<Divider><LockIcon className={"awesome-icon"}/></Divider>}
      onDialogClose={() => handleClose(false)}
      onDialogSubmit={onSubmit}
      open={open}
      sx={sx}
      {...rest}
  >
    <DimensionForm infoMessage={infoMessage}
                   errorMessage={fieldsErrorMessage}>
      <ClearableTextField value={username} setValue={setUsername} label={"Username/Email"}
                          onSubmit={onSubmit} errorMessage={usernameErrorMessage}
                          inputRef={inputRef}/>

      <PasswordTextField value={password} errorMessage={passwordErrorMessage}
                         label={getPasswordLabel()}
                         onSubmit={onSubmit}
                         setValue={setPassword}/>

      <FormControlLabel control={
        <Checkbox checked={rememberMe}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRememberMe(e.target.checked)}/>
      } label={"Trust This Device and Remember Me"} sx={{justifyContent: "center"}}/>

      <Stack direction={"row"} sx={{justifyContent: "space-evenly"}}>
        <IconTextButton icon={<PersonAddIcon sx={{fontSize: "80px"}}/>} text={"Sign Up"}
                        direction={"column"} variant={"h6"} onClick={
          () => {
            handleClose(false);
            openDialog("SignUpDialog");
          }
        }></IconTextButton>
        <IconTextButton icon={<MailLockTwoToneIcon sx={{fontSize: "80px"}}/>}
                        text={"Send Email OTP"}
                        direction={"column"} variant={"h6"}
                        onClick={onRequestEmailOtp}
        ></IconTextButton>

        <IconTextButton icon={<GoogleIcon sx={{fontSize: "80px"}}/>} text={"Google Login"}
                        direction={"column"} variant={"h6"}
                        onClick={() => googleLogin()}
        ></IconTextButton>

        <IconTextButton icon={<GitHubIcon sx={{fontSize: "80px"}}/>} text={"Github Login"}
                        direction={"column"} variant={"h6"}
                        onClick={() => githubLogin().catch((error) => {
                              setFieldsErrorMessage(error.message);
                            }
                        )}
        ></IconTextButton>


      </Stack>

    </DimensionForm>

  </DimensionDialog>;
}

export default React.memo(SignInDialog);