import {
  CollectorProfileQuery,
  CollectorProfileResponse,
  CollectorRequest,
  CollectorType
} from "../../proto/framework/collector/CollectorMessage";
import {ResourceType} from "../../proto/framework/ResourceMessage";
import {pb_pbPost, pb_strPost} from "../../api/util/MyDimensionClient";


const collectorAPIPrefix = "/api/v0/collector";

export const collect = (collectorType: CollectorType, resourceType: ResourceType, targetId: string, space_id?: string, score?: number) => {
  const collectorRequest = CollectorRequest.create();
  collectorRequest.type = collectorType;
  collectorRequest.targetType = resourceType;
  collectorRequest.targetId = targetId;

  if (space_id) {
    collectorRequest.spaceId = space_id;
  }
  if (score) {
    collectorRequest.score = score;
  }

  return pb_strPost(collectorAPIPrefix + "/collect", CollectorRequest, collectorRequest);
}

export const unCollect = (collectorType: CollectorType, resourceType: ResourceType, targetId: string, space_id?: string) => {
  const collectorRequest = CollectorRequest.create();
  collectorRequest.type = collectorType;
  collectorRequest.targetType = resourceType;
  collectorRequest.targetId = targetId;

  if (space_id) {
    collectorRequest.spaceId = space_id;
  }


  return pb_strPost(collectorAPIPrefix + "/unCollect", CollectorRequest, collectorRequest);
}

export const queryProfile = (resourceType: ResourceType, targetIds: string[]) => {
  if (targetIds.length === 0) {
    return Promise.resolve(CollectorProfileResponse.create());
  }

  const collectorProfileQuery = CollectorProfileQuery.create();
  collectorProfileQuery.targetType = resourceType;
  collectorProfileQuery.targetIds = targetIds;

  return pb_pbPost(collectorAPIPrefix + "/query", CollectorProfileQuery, CollectorProfileResponse, collectorProfileQuery);

}

const editableCollectorType = [CollectorType.COLLECTOR_TYPE_LIKE, CollectorType.COLLECTOR_TYPE_DISLIKE];

export const isReadOnly = (collectorType: CollectorType) => {
  return !editableCollectorType.includes(collectorType);
}


