// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.2
//   protoc               v5.28.0
// source: mydimension/framework/QueryMessage.proto

/* eslint-disable */
import {BinaryReader, BinaryWriter} from "@bufbuild/protobuf/wire";

export const protobufPackage = "proto.framework";

export interface PageQuery {
  /** current page number, start from 0 */
  page: number;
  /** page size, elements in one page */
  pageSize: number;
}

export interface PageResult {
  /** current page number, start from 0 */
  page: number;
  /** total page number */
  totalPage: number;
  /** page size, elements in one page */
  pageSize: number;
  /** total elements number */
  total: number;
}

export interface TimeRange {
  /** start time < end time in miliseconds default 0 */
  start?:
    | number
    | undefined;
  /** end time, default now */
  end?: number | undefined;
}

function createBasePageQuery(): PageQuery {
  return { page: 0, pageSize: 0 };
}

export const PageQuery = {
  encode(message: PageQuery, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.page !== 0) {
      writer.uint32(8).int32(message.page);
    }
    if (message.pageSize !== 0) {
      writer.uint32(16).int32(message.pageSize);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PageQuery {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePageQuery();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.page = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PageQuery {
    return {
      page: isSet(object.page) ? globalThis.Number(object.page) : 0,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
    };
  },

  toJSON(message: PageQuery): unknown {
    const obj: any = {};
    if (message.page !== 0) {
      obj.page = Math.round(message.page);
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PageQuery>, I>>(base?: I): PageQuery {
    return PageQuery.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PageQuery>, I>>(object: I): PageQuery {
    const message = createBasePageQuery();
    message.page = object.page ?? 0;
    message.pageSize = object.pageSize ?? 0;
    return message;
  },
};

function createBasePageResult(): PageResult {
  return { page: 0, totalPage: 0, pageSize: 0, total: 0 };
}

export const PageResult = {
  encode(message: PageResult, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.page !== 0) {
      writer.uint32(8).int32(message.page);
    }
    if (message.totalPage !== 0) {
      writer.uint32(16).int32(message.totalPage);
    }
    if (message.pageSize !== 0) {
      writer.uint32(24).int32(message.pageSize);
    }
    if (message.total !== 0) {
      writer.uint32(32).int64(message.total);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PageResult {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePageResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.page = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalPage = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.total = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PageResult {
    return {
      page: isSet(object.page) ? globalThis.Number(object.page) : 0,
      totalPage: isSet(object.totalPage) ? globalThis.Number(object.totalPage) : 0,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      total: isSet(object.total) ? globalThis.Number(object.total) : 0,
    };
  },

  toJSON(message: PageResult): unknown {
    const obj: any = {};
    if (message.page !== 0) {
      obj.page = Math.round(message.page);
    }
    if (message.totalPage !== 0) {
      obj.totalPage = Math.round(message.totalPage);
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.total !== 0) {
      obj.total = Math.round(message.total);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PageResult>, I>>(base?: I): PageResult {
    return PageResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PageResult>, I>>(object: I): PageResult {
    const message = createBasePageResult();
    message.page = object.page ?? 0;
    message.totalPage = object.totalPage ?? 0;
    message.pageSize = object.pageSize ?? 0;
    message.total = object.total ?? 0;
    return message;
  },
};

function createBaseTimeRange(): TimeRange {
  return { start: undefined, end: undefined };
}

export const TimeRange = {
  encode(message: TimeRange, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.start !== undefined) {
      writer.uint32(8).int64(message.start);
    }
    if (message.end !== undefined) {
      writer.uint32(16).int64(message.end);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TimeRange {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimeRange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.start = longToNumber(reader.int64());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.end = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TimeRange {
    return {
      start: isSet(object.start) ? globalThis.Number(object.start) : undefined,
      end: isSet(object.end) ? globalThis.Number(object.end) : undefined,
    };
  },

  toJSON(message: TimeRange): unknown {
    const obj: any = {};
    if (message.start !== undefined) {
      obj.start = Math.round(message.start);
    }
    if (message.end !== undefined) {
      obj.end = Math.round(message.end);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TimeRange>, I>>(base?: I): TimeRange {
    return TimeRange.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TimeRange>, I>>(object: I): TimeRange {
    const message = createBaseTimeRange();
    message.start = object.start ?? undefined;
    message.end = object.end ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
