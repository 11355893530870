import * as React from 'react';
import {CloseableDialogProps} from "../../../../component/dialog/DialogManager";
import {useTheme} from "@mui/material";
import {
  useAutoFocus,
  useFieldValidation,
  useHandleDialogClose
} from "../../../../hook/CommonUiHook";
import {validateDriverFilename} from "../../util/Utils";
import {addFolder} from "../../service/DriverService";
import {useAppSelector} from "../../../../api/redux/hooks";
import {selectInitialData} from "../../../../api/redux/slice/DialogSlice";
import {ExceptionResponse} from "../../../../proto/framework/ExceptionMessage";
import DimensionDialog from "../../../../component/dialog/DimensionDialog";
import Divider from "@mui/material/Divider";
import DimensionForm from "../../../../component/container/DimensionForm";
import ClearableTextField from "../../../../component/input/ClearableTextField";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import {DriverFileProfile} from "../../../../proto/application/driver/DriverMessage";

export type NewFolderDialogIType = {
  filePath: string;
  public: boolean;
}

export const NewFolderDialog = React.memo(({
                                             onDialogClose,
                                             initialData,
                                             open,
                                             ...rest
                                           }: CloseableDialogProps<NewFolderDialogIType>) => {
  const theme = useTheme();
  const handleClose = useHandleDialogClose<DriverFileProfile>("NewFolderDialog", onDialogClose);
  const initialDataFromManager = useAppSelector(selectInitialData<NewFolderDialogIType>);

  const filePath = initialData?.filePath || initialDataFromManager?.filePath || "/";
  const publicFolder = initialData?.public || initialDataFromManager?.public || false;

  const [newName, setNewName, errorMessage, setErrorMessage, validate] = useFieldValidation((filename) => {
    if (filename.length === 0) {
      return "Folder name cannot be empty";
    }
    if (!validateDriverFilename(filename)) {
      return "Folder name is invalid";
    }
  }, "new folder");

  const inputRef = useAutoFocus([open], (inputRef) => {
    inputRef.current?.setSelectionRange(0, newName.length);
  });

  const handleSubmit = async () => {
    if (!validate()) {
      return;
    }

    addFolder(newName, filePath, publicFolder).then((response) => {
      handleClose(true, response);
    }).catch((error: ExceptionResponse) => {
      setErrorMessage(error.message);
    });
  }

  return <DimensionDialog
      dialogTitle={"New Folder"}
      titleSx={{background: `linear-gradient(${theme.palette.warning.light}, #ffffff)`}}
      dialogStartNode={<Divider><CreateNewFolderIcon className={"awesome-icon"}/></Divider>}
      onDialogClose={() => handleClose(false)}
      primaryText={"Create"}
      open={open}
      onDialogSubmit={handleSubmit}
      {...rest}>
    <DimensionForm
        infoMessage={"Name you new folder"}>
      <ClearableTextField value={newName} setValue={setNewName} label={"New Filename"}
                          onSubmit={handleSubmit} errorMessage={errorMessage}
                          inputRef={inputRef}/>
    </DimensionForm>
  </DimensionDialog>
});
