import i18n from "i18next";
import {initReactI18next} from "react-i18next";

const resources = {
      en: {
        translation: {}
      },
      zh: {
        translation: {
          "OK": "完成",
          "Edit": "编辑",
          "Grid": "网格",
          "List": "列表",
          "Name": "名称",
          "Open": "打开",
          "Send": "发送",
          "Type": "类型",
          "USER": "用户",
          "User": "用户",
          "code": "代码",
          "fake": "虚假",
          "file": "文件",
          "grid": "网格",
          "here": "这里",
          "info": "信息",
          "link": "链接",
          "menu": "菜单",
          "name": "名称",
          "text": "文本",
          "time": "时间",
          "type": "类型",
          "user": "用户",
          "ADMIN": "管理员",
          "About": "关于",
          "Admin": "管理员",
          "Click": "点击",
          "Enter": "输入",
          "GUEST": "访客",
          "Guest": "游客",
          "Model": "模型",
          "Share": "共享",
          "audio": "音频",
          "block": "阻止",
          "error": "错误",
          "video": "视频",
          " path:": "路径:",
          "Cancel": "取消",
          "Create": "创建",
          "Delete": "删除",
          "Policy": "须知",
          "Public": "公开",
          "Remove": "移除",
          "Rename": "重命名",
          "Search": "搜索",
          "Source": "源链接",
          "Submit": "提交",
          "Update": "更新",
          "Upload": "上传",
          "policy": "政策",
          "random": "随机",
          "result": "结果",
          "search": "搜索",
          "signup": "注册",
          " second": "秒",
          "Log Out": "登出",
          "Message": "消息",
          "My Page": "我的主页",
          "Private": "私有",
          "Service": "服务",
          "Sharing": "共享",
          "Sign In": "登录",
          "Sign Up": "免费注册",
          "UnShare": "取消共享",
          "error: ": "错误:",
          "message": "消息",
          "PASSWORD": "密码",
          "Password": "密码",
          "Settings": "设置",
          "customer": "客户",
          "language": "语言",
          "password": "密码",
          "username": "用户名",
          "Username/Email": "用户名/邮箱",
          "validate": "验证",
          "vertical": "垂直",
          "Add Files": "添加文件",
          "Customize": "个性化",
          "Email OTP": "邮箱验证码",
          "Image URL": "图片链接",
          "Load More": "加载更多",
          "Re Upload": "重新上传",
          "WebDriver": "网盘",
          "New Folder": "新建文件夹",
          "Edit Avatar": "编辑头像",
          "Folder ID: ": "文件夹ID:",
          "determinate": "确定",
          "Account Name": "账户名",
          "Announcement": "公告",
          "Captcha Code": "图形验证码",
          "Chat History": "聊天记录",
          "Github Login": "Github登录",
          "Google Login": "Google登录",
          "New Filename": "新文件名",
          "New Password": "新密码",
          "New Username": "新用户名",
          "Recover here": "这里找回",
          "Update Email": "更新邮箱",
          "Click to copy": "点击复制",
          "Dimension 404": "第404号维度",
          "Edit Filename": "编辑文件名",
          "General Rules": "一般性规则",
          "Last Modified": "上次修改",
          "Search Engine": "搜索引擎",
          "Upload failed": "上传失败",
          "currentFile: ": "当前文件:",
          "Privacy Policy": "隐私政策",
          "Send Email OTP": "发送邮箱验证码",
          "client error: ": "客户端错误:",
          "server error: ": "服务器错误:",
          "Allow Auto Play": "允许自动播放",
          "Change Password": "修改密码",
          "network error: ": "网络错误:",
          "unknown error: ": "未知错误:",
          "Confirm Password": "确认密码",
          "Terms of Service": "服务条款",
          "Edit Announcement": "编辑公告",
          "Forget Password? ": "忘记密码？点击",
          "logout response: ": "登出响应:",
          "Drop You File Here": "将文件拖放到这里",
          "Failed to delete: ": "删除失败:",
          "Particle Animation": "粒子动画",
          "OTP cannot be empty": "验证码不能为空",
          "All rights reserved.": "保留所有权利。",
          "Confirm New Password": "确认新密码",
          "Edit Public Username": "编辑公开用户名",
          "Invalid Email Format": "邮箱格式错误",
          "Manage File on Cloud": "管理云端文件",
          "Captcha Code is empty": "图形验证码不能为空",
          "Error disconnecting: ": "断开连接时出错:",
          "New Password is Empty": "新密码不能为空",
          "Talk with AI for free": "AI聊天",
          "server error parsed: ": "服务器错误已解析:",
          "Folder name is invalid": "文件夹名称无效",
          "Invalid URL for Avatar": "头像地址无效",
          "Return To My Dimension": "返回我的维度",
          "server error decoded: ": "服务器错误已解码:",
          "Password is too simple.": "密码过于简单。",
          "Username does not exist": "用户名不存在",
          "Password cannot be empty": "密码不能为空",
          "Username cannot be empty": "用户名不能为空",
          "Confirm Password is Empty": "确认密码不能为空",
          "File format not supported": "文件格式不支持",
          "Click the image to refresh": "点击图片刷新",
          "Wrong username or password": "用户名或密码错误",
          "Edit Account name for Login": "编辑登录用户名",
          "Folder name cannot be empty": "文件夹名称不能为空",
          "Failed to change visibility: ": "更改可见性失败:",
          "Please check your email inbox": "请检查您的邮箱收件箱",
          "Ctrl + Enter to Send a message": "Ctrl + Enter 发送",
          "Confirm Password does not match": "确认密码不匹配",
          "Confirm Password cannot be empty": "确认密码不能为空",
          "File upload failed, please retry": "文件上传失败，请重试",
          "Upload failed. Please try again.": "上传失败，请重试。",
          "Not yet have an account? Sign Up ": "还没有账号？注册请点击",
          "Socket disconnected, retrying in ": "套接字已断开连接，正在重试，剩余时间",
          "Trust This Device and Remember Me": "信任此设备并记住我",
          "Username is a public name for you.": "用户名是您的公开名称。",
          "Execute recaptcha not yet available": "执行recaptcha尚不可用",
          "Send One Time Password To The Email": "发送一次性密码到邮箱",
          "Use an image URL or upload an image.": "使用图像链接或上传图像。",
          "Share your thoughts on your home page.": "在您的主页上分享您的想法。",
          "Email is the same as the existing email": "邮箱与现有邮箱相同",
          "Google Login is currently not Available": "Google登录当前不可用",
          "Socket not connected, giving up sending": "套接字未连接，放弃发送",
          "Socket not connected, retrying in 1 second": "套接字未连接，1秒后重试",
          "Dark Mode (might cause lag on page loading)": "暗黑模式（可能导致页面加载卡顿）",
          "Description should less than 1000 characters": "描述应少于1000个字符",
          "Something went wrong, please try again later": "出现问题，请稍后再试",
          "Value is larger than Number.MAX_SAFE_INTEGER": "值大于Number.MAX_SAFE_INTEGER",
          "Value is smaller than Number.MIN_SAFE_INTEGER": "值小于Number.MIN_SAFE_INTEGER",
          "Please leave your comments and feedback below!": "请在下方留下您的评论和反馈！",
          "Cookie is used for persisting your login record.": "网站使用Cookie保存您的登录记录。",
          "OTP has been sent to your email, please check it.": "验证码已发送到您的电子邮件，请查收。",
          "Account name is used for login, it must be a unique string.": "账户名用于登录，必须是唯一的。",
          "My Dimension: A full stack solution for deploying web ideas.": "My Dimension: 部署网络创意的全栈方案。",
          "Something is wrong with email login, Please try another login method.": "邮箱登录出现问题，请尝试其他登录方式。",
          "By using this website, you agree to be bound by these terms of service.": "通过使用本网站，您同意受这些服务条款的约束。",
          "You can send the link of this chat to other people to let them join this chat!": "您可以发送此聊天的链接给其他人，让他们加入这个聊天！",
          "Account name is used for login to your account. You can edit it by clicking on it.": "账户名用于登录您的账户。您可以点击它进行编辑。",
          "Password is too simple, please combine with letters, numbers and special characters": "密码太简单，请结合字母、数字和特殊字符",
          "Feel free to explore and support us. Any questions or feedback? You can reach out to us at: ": "欢迎尽情探索，我们欢迎任何问题和意见：",
          "You are trying to access a page that requires login. Please login or create an account first.": "您正在尝试访问需要登录的页面。请先登录或创建帐户。",
          "Renaming Your File. Make sure the name is within 255 characters and has no unsupported characters.": "正在重命名您的文件。请确保文件名在255个字符以内且不包含不支持的字符。",
          "You are current a guest, adding an email or sign in with a third party account would upgrade your account to a registered user.": "您当前是游客，添加电子邮件或使用第三方帐户登录可以升级为注册用户。",
          "Please be mindful of protecting your personal privacy. Do not post any information that could potentially harm your own or others": "请注意保护您的个人隐私。不要发布任何可能对您或他人造成伤害的信息。",
          "Please be mindful of protecting your personal privacy. Do not post any information that could potentially harm your own or others' personal privacy.": "请注意保护您的个人隐私。不要发布任何可能损害您或他人个人隐私的信息。",
          "You can upload original content or embed any third-party content to this website, but you are also responsible for the quality and legality.": "您可以上传原创内容或转载任何第三方内容到本网站，但您需要对内容的质量和合法性负责。",
          "We may suspend or terminate your access to this website at any time without notice if we believe that you have violated these terms of service.": "如果我们认为您违反了这些服务条款，我们可能会随时暂停或终止您对本网站的访问权，而无需事先通知。",
          "Your password is encrypted. You can edit it by clicking on it. Please provide recovery email so that we can help you recover your password when you forget it.": "您的密码已加密。您可以点击它进行编辑。请提供恢复电子邮件，以便我们在您忘记密码时帮助您恢复。",
          "Password must be at least 6 characters long, and must include at least two types of characters from uppercase letter, lowercase letter, number and and punctuation": "密码长度至少为6个字符，并且必须包含大写字母、小写字母、数字和标点符号中的至少两种。",
          "We reserve the right to change these terms of service at any time. You are responsible for reviewing these terms regularly to ensure that you are aware of any changes.": "我们保留随时更改这些服务条款的权利。您有责任定期查看这些条款，以确保您了解任何更改。",
          "My Dimension is a full stack project and is currently under active development. It aims at making it easy for people to deploy customer-oriented functionality to the web.": "My Dimension是一个正在开发中的全栈项目，致力于让人们能够轻松地将面向客户的功能部署到网络上。",
          "We respect your privacy and we are committed to protecting your personal information. We will not share your account information with any third party without your consent.": "我们尊重您的隐私，致力于保护您的个人信息。我们不会在未经您同意的情况下与任何第三方分享您的帐户信息。",
          "We might delete any improper content when we have to. Please use general moral standards to judge whether the content you provide is appropriate. Please respect other people when interacting with them.": "我们可能会在必要时删除任何不当内容。请您使用一般的道德标准来判断您提供的内容是否合适。在与他人互动时，请尊重他人。",
          "Please remember that our website is maintained by individual developers, and we cannot guarantee that our website will run stably and freely forever. When using the website, please ensure that you understand this risk. If you would like to support our website, please contact the admin. Your support is greatly appreciated and contributes significantly to the long-term development of the website.": "请记住我们的网站由个人开发者维护，我们无法保证我们的网站能长久稳定免费地运行，在使用网站时请确保你了解此项风险。如果您希望支持我们的网站，请联系站长，您的支持对网站的长期发展有非常大的帮助",

          "Storage": "存储",
          "account": "账号",
          "Account": "账号",
          "captcha": "图片验证码",
          "storage": "存储",

          "All": "全部",
          "Link": "链接",
          "Select": "选择",
          "target": "目标",
          "Filename": "文件名",
          "children": "子项",
          "Selecting": "选择",
          "selecting": "选择",
          "new folder": "新建文件夹",
          "upload error": "上传错误",
          "New Link File": "新建链接文件",
          "Link is invalid": "链接无效",
          "Failed to upload: ": "上传失败:",
          "Items are moved to ": "项目已移动到",
          "Name you new folder": "为您的新文件夹命名",
          "Link cannot be empty": "链接不能为空",
          "Select Or Drop Files To Upload": "选择或拖放文件进行上传",
          "Create a File with External Link": "创建外部链接文件",
          "Items are moved to a public folder": "项目已经移动到公共文件夹",
          "Items are moved to the root folder": "项目已经移动到根目录",
          "Items are moved to a private folder": "项目已经移动到私有文件夹",

          "Already have an account? ": "已经有账号？",

          "Copy Link": "复制链接",
          "Failed to change: ": "修改失败:",
          "Move": "移动",
          "items": "项",
          "selected": "选中",
          "Move File": "移动文件",
          "Target Path": "目标路径",
          "Path is the same as the current path": "路径与当前路径相同",
          "You can also drag and drop these files to the link of the target folder. Make sure the target path exists.": "你也可以直接将这些文件拖放到目标文件夹的链接中。请确保目标路径存在。",


          "response: ": "响应:",
          "Start your own web service": "开始创建属于您的网络服务",
          "We adopt spring security framework": "我们采用了Spring Security安全框架",
          "We use MongoDB for our database": "我们使用MongoDB作为主要数据库",
          "We use JWT for our authentication": "我们使用JWT进行身份验证",
          "Our web fronted is built with React": "我们的前端使用了React构建",
          "We use Google Open Source Technology.": "我们使用了Google开源技术。",
          "We integrate OpenAI in our application": "我们在应用中集成了OpenAI",
          "AWS is our major cloud service provider": "AWS是我们的主要云服务提供商",
          "Our backend is built with Spring Boot 3": "我们的后端采用Spring Boot 3构建",
          "We use Amazon S3 for our object storage": "我们使用Amazon S3进行对象存储",
          "We use Material-UI for our UI components": "我们使用Material-UI进行UI组件开发",
          "We use Elasticsearch for our search engine": "我们使用Elasticsearch作为搜索引擎",
          "Docker is the main technology adopted by 1Panel": "Docker是1Panel采用的主要技术",
          "Google Cloud is one of our cloud services provider": "Google Cloud是我们的云服务提供商之一",
          "Java is the main programming language for our backend": "Java是我们后端的主要编程语言",
          "We use 1Panel for operating our backend infrastructure": "我们使用1Panel作为后端运维工具",
          "TypeScript is the main programming language for our frontend": "TypeScript是我们前端的主要编程语言",
          "We use Python to build a code base management infrastructure": "我们使用Python构建代码库管理基础设施",
          "Let's Encrypt is our SSL certificate provider": "Let's Encrypt是我们的SSL证书提供商",
          "We use GitHub for version control, CI/CD and Oauth": "我们使用GitHub进行版本控制、CI/CD和Oauth",

          "Uploading": "上传中",
          "Finished": "完成",


          "Like": "点赞",
          "Time": "时间",
          "like": "赞",
          "Hello": "你好",
          "Reply": "回复",
          "View": "查看",
          "World": "世界",
          "close": "关闭",
          "dialog": "对话框",
          "Gallery": "画廊",
          "gallery": "图库",
          "un read": "未读",
          "variant": "变体",
          "replies": "回复",
          "has read": "已读",
          "api_error": "API错误",
          "online user": "在线用户",
          "Notification": "通知",
          "Retrying in": "重试中，剩余时间",
          "notification": "通知",
          "encountered:": "遇到：",
          "online user:": "在线用户：",
          "Type a message": "输入消息",
          "Got client id:": "获取到客户端ID：",
          "Leave a comment": "留下评论",
          "receive_message": "收到消息",
          "while handing:": "处理时：",
          "Mark All As Read": "全部标记为已读",
          "Show Unread Only": "仅显示未读",
          "Type anything...": "输入任何内容...",
          "renew page result": "刷新页面结果",
          "Message is not json": "消息不是JSON格式",
          "Message is not text": "消息不是文本格式",
          "initialHash on close": "关闭时的初始哈希",
          "notification_context": "通知上下文",
          "Message is not binary": "消息不是二进制格式",
          "User not authenticated": "用户未认证",
          "Comment cannot be empty": "评论不能为空",
          "Failed to load comments": "加载评论失败",
          "Error renewing client id:": "更新客户端ID出错：",
          "failed to get unread count": "获取未读数失败",
          "We use Redux for state management": "我们使用Redux进行状态管理",
          "View ": "查看 ",
          " replies": " 条回复",
          "Comments": "评论",
          "New Comment": "新评论",
          "New Reply": "新回复",
          "New Follower": "新粉丝",
          "New Like on Your Comment": "你的评论获得点赞",
          "Follow": "关注",
          "Unfollow": "取消关注",
          "online user: ": "在线用户: ",
          "You cannot follow yourself": "无法关注自己",

          "Analytic": "分析",
          "End Date": "结束日期",
          "function": "函数",
          "analytics": "分析",
          "mmd_model": "MMD 模型",
          "Start Date": "开始日期",
          "Light Color": "灯光颜色",
          "Customer IDs": "客户ID",
          "Light Position": "灯光位置",
          "Light Intensity": "灯光强度",
          "BabyLon 3D Engine": "BabyLon 3D 引擎",
          "Interval (minutes)": "间隔（分钟）",
          "Ambient Light Color": "环境光颜色",
          "Error loading VMD file": "加载VMD文件出错",
          "Ambient Light Intensity": "环境光强度",
          "directionalLight": "方向光",
          "hemisphericLight": "环境光",
          "pointLight": "点光源",
          "node": "节点",
          "view": "浏览",
          "follow": "关注",
          "rating": "评价",
          "unlike": "不喜欢",
          "collect": "收藏",
          "download": "下载",
          "added fog": "添加雾气",
          " hours ago": "小时前",
          "production": "生产",
          "development": "开发",
          " minutes ago": "分钟前",
          "Added shadow": "添加阴影",
          "Invalid profile response": "无效的响应",
        }
      }
    }
;

i18n
.use(initReactI18next) // passes i18n down to react-i18next
.init({
  resources,
  lng: localStorage.getItem("language") ? localStorage.getItem("language") as string : window.navigator.language ? window.navigator.language : "en",
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18n;