import React, {useEffect} from 'react';
import {BoxProps} from "@mui/material";
import {selectEnableDarkMode, setDarkMode} from "../api/redux/slice/SettingSlice";
import {useAppDispatch, useAppSelector} from "../api/redux/hooks";
import {useWebSocketProvider, WebsocketContext} from "./websocket/WebsocketContext";
import {NotificationContext, useNotificationProvider} from "./NotificationContext";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

const GlobalProviderContainer = ({children}: BoxProps) => {
  const dispatch = useAppDispatch();
  const enableDarkMode = useAppSelector(selectEnableDarkMode);
  const {message, sendSocketMessage, subscribeTo, connected} = useWebSocketProvider();
  const {unReadNotification, ack} = useNotificationProvider();

  useEffect(() => {
    dispatch(setDarkMode(enableDarkMode));
  }, []);

  return <WebsocketContext.Provider value={
    {
      message: message,
      sendSocketMessage: sendSocketMessage,
      subscribeTo: subscribeTo,
      connected: connected,
    }
  }>
    <NotificationContext.Provider value={{unReadNotification, ack}}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      {children}
      </LocalizationProvider>
    </NotificationContext.Provider>
  </WebsocketContext.Provider>;
}

export default GlobalProviderContainer;