import React, {useEffect} from "react";
import PortalNavigationBar from "../../../navigation/PortalNavigationBar";
import {Outlet} from "react-router-dom";
import PortalFooter from "../footer/PortalFooter";
import {Box} from "@mui/material";
import {AuthenticationRequest, AuthenticationType} from "../../../proto/framework/SecurityMessage";
import {signIn} from "../../account/service/AuthenticationService";
import AlertContext from "../../../context/AlertContext";
import OneOf from "../../../component/container/OneOf";
import LoadingScreen from "./LoadingScreen";
import ClientLogger from "../component/ClientLogger";
import {enable_client_log} from "../../../api/applicationConfig";

export const PAGE_MIN_HEIGHT = "75svh";
// Portal 是门户页面，包含导航栏，子页面，底部栏，是经典的门户页面布局，绝大多数子元素都通过门户页面的二级路由进行导航
const Portal = () => {
  const {showAlert} = React.useContext(AlertContext);
  const [redirecting, setRedirecting] = React.useState(false);

  useEffect(() => {
    const parsedUrl = new URL(window.location.href);

    const githubRememberMe = parsedUrl.searchParams.get("GithubRememberMe");
    const code = parsedUrl.searchParams.get("code");

    if (githubRememberMe !== null && code !== null) {
      setRedirecting(true);
      const request = AuthenticationRequest.create();
      request.authenticationType = AuthenticationType.GITHUB_OAUTH;
      request.userIdentifier = "Github User"; // unused
      request.credential = code;
      request.rememberMe = githubRememberMe === "true";

      signIn(request).then(() => {
        // redirect to home page
        window.location.href = "/";

      }).catch((error) => {
        showAlert(error.message, "error");
      }).finally(() => {
        setRedirecting(false);
      });
    }
  }, []);


  return <div>
    <ClientLogger init={enable_client_log} freq={3000}/>
    <PortalNavigationBar sx={{
      paddingX: {
        xs: 0,
        sm: 0,
        md: 0,
        lg: 24,
        xl: 48
      }
    }}/>
    <Box sx={{
      marginTop: {
        xs: 2,
        sm: 2,
        md: 2,
        lg: 2,
        xl: 2
      },
      marginX: {
        xs: 0,
        sm: 2,
        md: 6,
        lg: 24,
        xl: 48
      },
      maxWidth: "100dvw",
      // overflow: "hidden",
      minHeight: PAGE_MIN_HEIGHT,
    }}>
      <OneOf index={redirecting ? 1 : 0}>
        <Outlet/>
        <LoadingScreen/>
      </OneOf>
    </Box>
    <PortalFooter/>
  </div>;
}

export default Portal;