import {ResourceType} from "../../proto/framework/ResourceMessage";
import {pb_pbPost, str_strPost, throttle} from "../../api/util/MyDimensionClient";
import {
  CommentProfile,
  CommentQuery,
  CommentRequest,
  CommentResponse
} from "../../proto/framework/comment/CommentMessage";
import {PageQuery} from "../../proto/framework/QueryMessage";
import {CollectorType} from "../../proto/framework/collector/CollectorMessage";

const commentAPIPrefix = '/api/v0/comment';

export const commentOn = (content: string, targetType: ResourceType, targetId: string, replyTo?: string, attachTo?: string) => {
  const comment = CommentRequest.create();
  comment.content = content;
  comment.targetType = targetType;
  comment.targetId = targetId;
  if (replyTo) {
    comment.replyTo = replyTo;
    comment.attachToId = attachTo;
  }

  return pb_pbPost(commentAPIPrefix + "/comment", CommentRequest, CommentProfile, comment);
}

export const deleteComment = (commentId: string) => {
  return str_strPost(commentAPIPrefix + "/removeComment", commentId);
}

export const queryComment = throttle((targetType: ResourceType, targetId: string, page = 0, pageSize = 30, attachTo?: string) => {
  const commentQuery = CommentQuery.create();
  const pageQuery = PageQuery.create();
  pageQuery.page = page;
  pageQuery.pageSize = pageSize;

  commentQuery.targetType = targetType;
  commentQuery.targetId = targetId;
  commentQuery.pageQuery = pageQuery;
  if (attachTo) {
    commentQuery.attachToId = attachTo;
  }

  return pb_pbPost(commentAPIPrefix + "/query", CommentQuery, CommentResponse, commentQuery);
});

export const likeComment = (commentId: string) => {
  return str_strPost(commentAPIPrefix + "/like", commentId);
}


export const unlikeComment = (commentId: string) => {
  return str_strPost(commentAPIPrefix + "/unlike", commentId);
}



export const getUserLike = (comment: CommentProfile) => {
  if (!comment.userCollectState) {
    return false;
  }
  for (let i = 0; i < comment.userCollectState.collectStates.length; i++) {
    if (comment.userCollectState.collectStates[i].type === CollectorType.COLLECTOR_TYPE_LIKE) {
      return true;
    }
  }
  return false;
};

export const getLikeCount = (comment: CommentProfile) => {
  if (!comment.collectProfile) {
    return 0;
  }
  for (let i = 0; i < comment.collectProfile.profiles.length; i++) {
    if (comment.collectProfile.profiles[i].type === CollectorType.COLLECTOR_TYPE_LIKE) {
      return comment.collectProfile.profiles[i].count;
    }
  }
  return 0;
};

export type SortType = "time" | "like";