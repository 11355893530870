import * as React from 'react';
import {useEffect} from 'react';
import {Pagination, Stack, StackProps} from "@mui/material";
import {queryComment} from "../CommentService";
import {ResourceType} from "../../../proto/framework/ResourceMessage";
import {CommentProfile} from "../../../proto/framework/comment/CommentMessage";
import AlertContext from "../../../context/AlertContext";
import {PageResult} from "../../../proto/framework/QueryMessage";
import ListComponent from "../../../component/container/ListComponent";
import CommentItem from "./CommentItem";
import ContentButton from "../../../component/button/ContentButton";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OneOf from "../../../component/container/OneOf";
import {print} from "../../../api/util/Logging";
import {useTranslation} from "react-i18next";

type AttachedThreadProps = {
  targetType: ResourceType;
  targetId: string;
  attachTo: string;
  count: number;
  refresh?: boolean;
  page?: number;
  pageSize?: number;
  canDelete?: boolean;
} & StackProps
const AttachedThread = ({
                          targetType,
                          targetId,
                          attachTo,
                          refresh = void 0,
                          count,
                          page = 0,
                          pageSize = 10,
                          canDelete = false,
                          sx, ...rest
                        }: AttachedThreadProps) => {
  // epxandable thread, query when expand
  const {showAlert} = React.useContext(AlertContext);
  const {t} = useTranslation();
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [comments, setComments] = React.useState<CommentProfile[]>([]);
  const defaultPageResult = PageResult.create();
  defaultPageResult.page = page;
  defaultPageResult.pageSize = pageSize;
  defaultPageResult.totalPage = 0;
  defaultPageResult.total = 0;
  const [pageResult, setPageResult] = React.useState<PageResult>(defaultPageResult);

  useEffect(() => {
    if (!expanded) {
      return;
    }

    queryComment(targetType, targetId, page, pageSize, attachTo).then((response) => {
      setComments(response.comments);
      if (response.pageResult) {
        setPageResult(response.pageResult);
      }
    }).catch((e) => {
      showAlert("Failed to load comments", "error");
    });

  }, [expanded]);

  // refresh to the last page
  useEffect(() => {
    if (refresh === void 0) {
      return;
    }

    if (!expanded) {
      setExpanded(true);
      return;
    }

    queryComment(targetType, targetId, Math.max(pageResult.totalPage - 1, 0), pageResult.pageSize, attachTo).then((response) => {
      setComments(response.comments);
      if (response.pageResult) {
        print("renew page result", response.pageResult);
        setPageResult(response.pageResult);
      }
    }).catch((e) => {
      showAlert("Failed to load comments", "error");
    });

  }, [refresh]);

  return <Stack direction={"column"} spacing={1} sx={{...sx}} {...rest}>
    <OneOf index={expanded ? 1 : 0}>
      <Stack direction={"row"} spacing={1}>
        <ContentButton title={t("View ") + count + t(" replies")}
                       icon={<ExpandMoreIcon/>}
                       color={"primary"}
                       variant={"text"}
                       size={"small"}
                       sx={{borderRadius: 10}}
                       disableElevation={true}
                       onClick={() => {
                         setExpanded(true);
                       }}/>
      </Stack>
      <Stack direction={"column"} spacing={1} width={"100%"} justifyContent={"flex-start"}>
        <ListComponent data={comments} renderItem={(comment, index) => {
          return <CommentItem
              targetType={targetType}
              targetId={targetId}
              attachTo={attachTo}
              canDelete={canDelete}
              onReply={(newComment) => {
                setComments([newComment, ...comments]);
              }
              }
              onDeleted={() => {
                setComments(comments.filter((item) => {
                  return item.commentId !== comment.commentId;
                }));
              }
              }
              comment={comment} key={comment.commentId + ":" + index}/>;
        }}/>
        {pageResult.totalPage > 1 &&
            <Pagination count={pageResult.totalPage}
                        page={pageResult.page + 1}
                        onChange={(event, value) => {
                          queryComment(targetType, targetId, value - 1, pageSize, attachTo).then((response) => {
                            setComments(response.comments);
                            if (response.pageResult) {
                              setPageResult(response.pageResult);
                            }
                          }).catch((e) => {
                            showAlert("Failed to load comments", "error");
                          });
                        }}/>}
      </Stack>
    </OneOf>
  </Stack>;
};

export default AttachedThread;
