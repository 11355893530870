import {Link as MuiLink, Stack} from "@mui/material";
import {Link} from "react-router-dom"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from "react";
import {faBolt} from "@fortawesome/free-solid-svg-icons";
import {faBilibili, faGithub, faLinkedin, faYoutube} from '@fortawesome/free-brands-svg-icons'
import SmartDivider from "../component/SmartDivider";
import CopyrightFooter from "./CopyrightFooter";
import {Typography} from "../../../component/wrapper/MuiWrappers";
import ImageContainer from "../../../component/container/ImageContainer";
import Divider from "@mui/material/Divider";
import BreakpointContainer from "../../../component/container/BreakpointContainer";
import ListComponent from "../../../component/container/ListComponent";
import {get_root_url} from "../../../navigation/routerUtil";

const avatars = [
  {
    link: "https://www.linkedin.com/in/xinyu96/",
    icon: faLinkedin,
    alt: "LinkedIn",
  },
  {
    link: "https://github.com/xzhuah",
    icon: faGithub,
    alt: "Github",
  },
  {
    link: "https://space.bilibili.com/181717176/",
    icon: faBilibili,
    alt: "Bilibili",
  },
  {
    link: "https://afdian.com/a/ycyxz",
    icon: faBolt,
    alt: "Afdian",
  },
  {
    link: "https://www.youtube.com/channel/UCIisTgTfkG25LZMmXaRBlPQ",
    icon: faYoutube,
    alt: "Youtube",
  }
];

const PortalFooter = () => {
  return <Stack direction={"column"} justifyContent={"center"} alignItems={"center"}
                sx={{
                  marginX: {
                    xs: 6,
                    sm: 6,
                    md: 6,
                    lg: 24,
                    xl: 48
                  },
                  height: "auto",
                  paddingTop: "1rem",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}>

    <SmartDivider/>

    <Stack direction={"row"}>
      <Stack direction={"row"} alignItems={"center"} spacing={2}>
        <Link to={get_root_url()}>
          <ImageContainer src={"/hive.png"} sx={{width: "80px", height: "80px"}}/>
        </Link>
        <Divider orientation={"vertical"}/>

        <Stack direction={"column"} alignItems={"start"}>
          <BreakpointContainer up={"md"}>
            <Typography variant={"h4"}>My Dimension</Typography>
          </BreakpointContainer>
          <BreakpointContainer down={"md"}>
            <Typography variant={"h6"}>My Dimension</Typography>
          </BreakpointContainer>
          <CopyrightFooter alignItems={"start"} textAlign={"start"}/>
          <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={{
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 4
          }}
                 marginTop={2}>
            <ListComponent data={avatars} renderItem={(avatar, index) => {
              return <MuiLink href={avatar.link} target={"_blank"} key={avatar.link}>
                <FontAwesomeIcon icon={avatar.icon} fontSize={32} className={"awesome-icon"}
                                 color={index ?? 0 % 2 === 0 ? "#FFC425" : "#FCEC6E"}/>
              </MuiLink>;
            }}/>
          </Stack>
        </Stack>
      </Stack>
    </Stack>


  </Stack>
}

export default PortalFooter;