import * as React from 'react';
import {CollectorType} from "../../../proto/framework/collector/CollectorMessage";
import {Stack, StackProps, SxProps} from "@mui/material";
import {Typography} from "../../../component/wrapper/MuiWrappers";
import {toReadableNumber} from "../../../api/util/StringUtil";
import CollectorIcon from "./CollectorIcon";
import AlertContext from "../../../context/AlertContext";
import {useEffect} from "react";
import {IconBaseProps} from "react-icons/lib/iconBase";
import {TypographyProps} from "@mui/material/Typography";

type Props = {
  collectorType: CollectorType;
  number: number;
  collected: boolean;
  onCollectChange: (collect: boolean) => void | Promise<void>;
  readOnly?: boolean;
  iconProps?: IconBaseProps;
  numberProps?: TypographyProps;
} & StackProps;
const CollectorButton = ({
                           collectorType,
                           number,
                           collected,
                           onCollectChange,
                           readOnly = false,
                           iconProps,
                           numberProps,
                           sx,
                           ...rest
                         }: Props) => {

  const [onMouseHover, setOnMouseHover] = React.useState<boolean>(false);
  const [hasCollect, setHasCollect] = React.useState<boolean>(collected);
  const {showAlert} = React.useContext(AlertContext);
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    setHasCollect(collected);
  }, [collected]);

  return <Stack direction={"row"} alignItems={"center"}
                spacing={1}
                className={!readOnly ? (hasCollect ? "yellow_icon" : "awesomepointer") : "gray_icon"}
                onMouseEnter={() => setOnMouseHover(true)}
                onMouseLeave={() => setOnMouseHover(false)}
                onClick={async (e) => {
                  if (readOnly || loading) {
                    return;
                  }
                  e.stopPropagation();
                  setLoading(true);
                  setHasCollect((currentCollect) => {
                    return !currentCollect;
                  });

                  try {
                    await onCollectChange(!collected);
                  } catch (e: any) {
                    setHasCollect((currentCollect) => {
                      return !currentCollect;
                    });
                    if (e.message) {
                      showAlert(e.message, "error");
                    } else {
                      showAlert(e.toString(), "error");
                    }
                  } finally {
                    setLoading(false);
                  }
                }} sx={{...sx}} {...rest}>
    <CollectorIcon collectorType={collectorType}
                   collected={hasCollect || (!readOnly && onMouseHover)} {...iconProps}></CollectorIcon>
    <Typography variant={"body1"}
                enableTranslate={false} {...numberProps}>{toReadableNumber(number)} </Typography>
  </Stack>
};

export default CollectorButton