import React from "react";
import FeaturePanel from "./FeaturePanel";
import DimensionDialog from "../../../component/dialog/DimensionDialog";
import {CloseableDialogProps} from "../../../component/dialog/DialogManager";
import {useHandleDialogClose} from "../../../hook/CommonUiHook";
import {Stack} from "@mui/material";

const CustomizeSettingDialog = ({onDialogClose, open, ...rest}: CloseableDialogProps) => {
  const handleClose = useHandleDialogClose("CustomizeSettingDialog", onDialogClose);

  return <DimensionDialog
      dialogTitle={"Customize"}
      dialogStartNode={<></>}
      dialogEndingNode={<></>}
      // No submit
      onDialogClose={() => handleClose(true)}
      primaryText={"OK"}
      open={open}
      onDialogSubmit={() => handleClose(true)}
      hideCancel={true}

      {...rest}>
    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
    <FeaturePanel alignItems={"start"} width={{
      xs: "100%",
      md: "60%",
    }}/>
    </Stack>
  </DimensionDialog>
}

export default  React.memo(CustomizeSettingDialog);