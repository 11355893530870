import {pb_pbPost, str_pbPost, throttle} from "../../../api/util/MyDimensionClient";
import {CustomerProfile, SetEmailRequest} from "../../../proto/framework/customer/CustomerInfo";
import store from "../../../api/redux/store";
import {
  setAvatarUrl as setAvatar,
  setCustomerName as setName,
  setUserRole
} from "../../../api/redux/slice/SettingSlice";
import {collect, queryProfile, unCollect} from "../../collector/CollectorService";
import {CollectorType} from "../../../proto/framework/collector/CollectorMessage";
import {ResourceType} from "../../../proto/framework/ResourceMessage";

const customerAPIPrefix = '/api/v0/customer';

export const queryCustomerProfile = (userId?: string | null) => {
  return str_pbPost(customerAPIPrefix + "/query", CustomerProfile, userId);
};

export const setPrimaryEmail = throttle((email: string, otp: string) => {
  const setEmailRequest = SetEmailRequest.create();
  setEmailRequest.email = email;
  setEmailRequest.otp = otp;

  return pb_pbPost(customerAPIPrefix + "/setEmail", SetEmailRequest, CustomerProfile, setEmailRequest, {
    headers: {
      "Otp": otp
    }
  }).then(res => {
    store.dispatch(setUserRole(res.role));
    return res;
  });
});

export const setCustomerName = throttle((username: string) => {
  return str_pbPost(customerAPIPrefix + "/setUsername", CustomerProfile, username).then(res => {
    store.dispatch(setName(res.name));
    return res;
  });
});

export const setDescription = throttle((description: string) => {
  return str_pbPost(customerAPIPrefix + "/setDescription", CustomerProfile, description);
});

export const setAvatarUrl = throttle((avatarUrl: string) => {
  return str_pbPost(customerAPIPrefix + "/setAvatarUrl", CustomerProfile, avatarUrl).then(res => {
    store.dispatch(setAvatar(res.avatarUrl));
    return res;
  });
});

export const follow = throttle((userId: string) => {
  return collect(CollectorType.COLLECTOR_TYPE_FOLLOW, ResourceType.RESOURCE_TYPE_CUSTOMER, userId);
});

export const unFollow = throttle((userId: string) => {
  return unCollect(CollectorType.COLLECTOR_TYPE_FOLLOW, ResourceType.RESOURCE_TYPE_CUSTOMER, userId);
});

export type FollowState = {
  followerCount: number,
  hasFollowed: boolean
}

export const getUserFollowState = (userId?: string | null) => {
  if (!userId) {
    return Promise.resolve({
      followerCount: 0,
      hasFollowed: false
    } as FollowState);
  }
  return getFollowState([userId]).then(res => res[0]);
}

export const getFollowState = throttle((userId: string[]) => {
  return queryProfile(ResourceType.RESOURCE_TYPE_CUSTOMER, userId).then(res => {
    const result = [] as FollowState[];
    res.profiles.forEach(profile => {
      const followState = {
        followerCount: 0,
        hasFollowed: false
      } as FollowState;
      if (profile.targetProfile) {
        for (let i = 0; i < profile.targetProfile.profiles.length; i++) {
          if (profile.targetProfile.profiles[i].type === CollectorType.COLLECTOR_TYPE_FOLLOW) {
            followState.followerCount = profile.targetProfile.profiles[i].count;
            break;
          }
        }
      }
      if (profile.userCollectState) {
        for (let i = 0; i < profile.userCollectState.collectStates.length; i++) {
          if (profile.userCollectState.collectStates[i].type === CollectorType.COLLECTOR_TYPE_FOLLOW) {
            followState.hasFollowed = true;
            break;
          }
        }
      }
      result.push(followState);
    });
    return result;
  });
});