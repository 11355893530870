import {pb_pbPost, pb_strPost, str_strPost, throttle} from "../../api/util/MyDimensionClient";
import {print} from "../../api/util/Logging";
import {
  NotificationAckRequest,
  NotificationQuery,
  NotificationResponse
} from "../../proto/framework/notification/NotificationMessage";
import {PageQuery} from "../../proto/framework/QueryMessage";


const notificationAPIPrefix = "/api/v0/notification";

export const getUnReadCount = throttle(() => {
  return str_strPost(notificationAPIPrefix + "/count").then(res => {
    return parseInt(res);
  }).catch(
      () => {
        print("failed to get unread count");
        return 0;
      }
  )
});


export const ackNotification = (notificationIds: string[], remove = false) => {
  if (notificationIds.length === 0) {
    return Promise.resolve();
  }
  const notificationAckRequest = NotificationAckRequest.create();
  notificationAckRequest.notificationIds = notificationIds;
  notificationAckRequest.delete = remove;
  return pb_strPost(notificationAPIPrefix + "/ack", NotificationAckRequest, notificationAckRequest);
}

// if unreadOnly is true, page will be ignored
export const queryNotification = throttle((unreadOnly = false, pageSize = 50, pageIndex = 0) => {
  const query = NotificationQuery.create();
  query.unreadOnly = unreadOnly
  const pageQuery = PageQuery.create();
  pageQuery.page = pageIndex
  pageQuery.pageSize = pageSize
  query.pageQuery = pageQuery
  return pb_pbPost(notificationAPIPrefix + "/query", NotificationQuery, NotificationResponse, query);
});