import {useAppDispatch, useAppSelector} from "../../../api/redux/hooks";
import {selectLoginResponse, setLoginResponse} from "../../../api/redux/slice/SettingSlice";
import {openDialog} from "../../../component/dialog/DialogManager";
import {Badge, Stack, StackProps} from "@mui/material";
import ContentButton from "../../../component/button/ContentButton";
import BreakpointContainer from "../../../component/container/BreakpointContainer";
import MenuButton from "../../../component/button/MenuButton";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import LogoutIcon from '@mui/icons-material/Logout';
import {logout} from "../service/AuthenticationService";
import React from "react";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AlertContext from "../../../context/AlertContext";
import {
  get_account_url,
  get_customer_url,
  get_notification_url
} from "../../../navigation/routerUtil";
import ProfileAvatar from "../../customer/component/ProfileAvatar";
import OneOf from "../../../component/container/OneOf";
import {menuItemStyle} from "../../../api/util/constants";
import {WebsocketContext} from "../../../context/websocket/WebsocketContext";
import CircleIcon from '@mui/icons-material/Circle';
import NotificationBadge from "../../notification/components/NotificationBadge";
import NotificationsIcon from '@mui/icons-material/Notifications';

type AccountDropdownProps = {
  itemStyle?: any
} & StackProps;

const AccountDropdown = ({
                           itemStyle = menuItemStyle,
                           ...rest
                         }: AccountDropdownProps) => {
  const currentLoginResponse = useAppSelector(selectLoginResponse);
  const {showAlert} = React.useContext(AlertContext)
  const {connected} = React.useContext(WebsocketContext);
  const dispatch = useAppDispatch();
  return <OneOf index={currentLoginResponse === null ? 0 : 1}>
    <>
      <BreakpointContainer up={"md"}>
        <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} {...rest}>
          <ContentButton title={"Sign In"} onClick={() =>
              openDialog("SignInDialog")}></ContentButton>
          <ContentButton
              title={"Customize"}
              onClick={() => {
                openDialog("CustomizeSettingDialog")
              }}>
          </ContentButton>
        </Stack>
      </BreakpointContainer>
      <BreakpointContainer down={"md"}>
        <MenuButton title={"Account"}
                    icon={<AccountCircleIcon/>}
                    sx={{display: {xs: "flex", md: "none", backgroundColor: "transparent"}}}
                    menuItems={[
                      <ContentButton
                          title={"Sign In"}
                          sx={{
                            ...itemStyle
                          }}
                          onClick={() => {
                            openDialog("SignInDialog")
                          }}>
                      </ContentButton>,
                      <ContentButton
                          sx={{
                            ...itemStyle
                          }}
                          title={"Customize"}
                          onClick={() => {
                            openDialog("CustomizeSettingDialog")
                          }}>
                      </ContentButton>,
                    ]}></MenuButton>
      </BreakpointContainer>
    </>
    <MenuButton title={currentLoginResponse?.name}
                enableTranslate={false}
                icon={
                  <NotificationBadge>
                    <Badge sx={{}}
                           badgeContent={
                             <CircleIcon sx={{
                               color: connected ? "success.main" : "warning.main",
                               fontSize: "1rem"
                             }}/>}
                           overlap="circular"
                           anchorOrigin={{
                             vertical: 'bottom',
                             horizontal: 'right',
                           }}>
                      <ProfileAvatar title={currentLoginResponse?.name}
                                     avatarUrl={currentLoginResponse?.avatarUrl}/>
                    </Badge>
                  </NotificationBadge>
                }
                onClickExtra={(e) => {
                  // if (hasUser) {
                  //   updateMessageCount();
                  // }
                }}
                sx={{
                  backgroundColor: "transparent"
                }}
                menuItems={[<ContentButton
                    sx={{
                      ...itemStyle
                    }}
                    title={"My Page"}
                    to={get_customer_url()}
                    icon={<AccountCircleIcon/>}>
                </ContentButton>,
                  <ContentButton
                      sx={{
                        ...itemStyle
                      }}
                      title={"Account"}
                      to={get_account_url()}
                      icon={<AdminPanelSettingsIcon/>
                      }>
                  </ContentButton>,

                  <ContentButton
                      sx={{
                        ...itemStyle
                      }}
                      title={"Notification"}
                      to={get_notification_url()}
                      icon={<NotificationBadge><NotificationsIcon/></NotificationBadge>
                      }>
                  </ContentButton>,

                  <ContentButton
                      sx={{
                        ...itemStyle
                      }}
                      title={"Customize"}
                      onClick={(e) => {
                        e.stopPropagation();
                        openDialog("CustomizeSettingDialog")
                      }}
                      icon={<DisplaySettingsIcon/>}>
                  </ContentButton>,

                  <ContentButton
                      sx={{
                        ...itemStyle
                      }}
                      title={"Log Out"}
                      icon={<LogoutIcon/>}
                      onClick={(e) => {
                        e.stopPropagation();
                        logout().catch((error) => {
                          showAlert("Logout failed:" + error.message);
                          dispatch(setLoginResponse(null));
                        }).finally(() => {
                          // refresh page
                          window.location.reload();
                        });
                      }}>
                  </ContentButton>

                ]}></MenuButton>

  </OneOf>
}

export default React.memo(AccountDropdown);