import {Link, Stack, StackProps} from "@mui/material"
import React from "react";
import {Tooltip, Typography} from "../../component/wrapper/MuiWrappers";
import ImageContainer from "../../component/container/ImageContainer";
import {FaAws, FaDocker, FaGoogle, FaJava, FaPython, FaReact} from "react-icons/fa";
import {BiLogoSpringBoot} from "react-icons/bi";
import {
  Si1Panel,
  SiAmazons3,
  SiElasticsearch,
  SiGooglecloud,
  SiJsonwebtokens,
  SiLetsencrypt,
  SiMongodb,
  SiMui,
  SiRedux,
  SiSpringsecurity,
  SiTypescript
} from "react-icons/si";
import {ImGithub} from "react-icons/im";
import {AiOutlineOpenAI} from "react-icons/ai";
import {Grid} from "@mui/system";
import {DTARGET} from "../../api/util/constants";
import ListComponent from "../../component/container/ListComponent";
import {getObjectId} from "../../api/util/StringUtil";
import CommentWidget from "../comment/CommentWidget";
import {ResourceType} from "../../proto/framework/ResourceMessage";
import CollectorWidget from "../collector/CollectorWidget";
import {CollectorType} from "../../proto/framework/collector/CollectorMessage";
import ViewRecorder from "../collector/component/ViewRecorder";
import FeedbackWidget from "../website/component/FeedbackWidget";

const logoSize = 120;
const logos = [{
  logo: <FaReact size={logoSize}/>,
  link: "https://reactjs.org/",
  title: "Our web fronted is built with React"
},
  {
    logo: <SiMui size={logoSize}/>,
    link: "https://mui.com/",
    title: "We use Material-UI for our UI components"
  },
  {
    logo: <SiRedux size={logoSize}/>,
    link: "https://redux.js.org/",
    title: "We use Redux for state management"
  },
  {
    logo: <SiLetsencrypt size={logoSize}/>,
    link: "https://letsencrypt.org/",
    title: "Let's Encrypt is our SSL certificate provider"
  },
  {
    logo: <SiJsonwebtokens size={logoSize}/>,
    link: "https://jwt.io/",
    title: "We use JWT for our authentication"
  },
  {
    logo: <BiLogoSpringBoot size={logoSize}/>,
    link: "https://spring.io/projects/spring-boot",
    title: "Our backend is built with Spring Boot 3"
  },
  {
    logo: <SiSpringsecurity size={logoSize}/>,
    link: "https://spring.io/projects/spring-security",
    title: "We adopt spring security framework"
  },
  {
    logo: <ImGithub size={logoSize}/>,
    link: "https://github.com/",
    title: "We use GitHub for version control, CI/CD and Oauth"
  },
  {
    logo: <FaGoogle size={logoSize}/>,
    link: "https://opensource.google/",
    title: "We use Google Open Source Technology."
  },
  {
    logo: <SiGooglecloud size={logoSize}/>,
    link: "https://cloud.google.com/",
    title: "Google Cloud is one of our cloud services provider"
  },
  {
    logo: <AiOutlineOpenAI size={logoSize}/>,
    link: "https://openai.com/",
    title: "We integrate OpenAI in our application"
  },
  {
    logo: <Si1Panel size={logoSize}/>,
    link: "https://1panel.cn/",
    title: "We use 1Panel for operating our backend infrastructure"
  },
  {
    logo: <FaDocker size={logoSize}/>,
    link: "https://www.docker.com/",
    title: "Docker is the main technology adopted by 1Panel"
  },
  {
    logo: <SiMongodb size={logoSize}/>,
    link: "https://www.mongodb.com/",
    title: "We use MongoDB for our database"
  },
  {
    logo: <SiElasticsearch size={logoSize}/>,
    link: "https://www.elastic.co/",
    title: "We use Elasticsearch for our search engine"
  },
  {
    logo: <FaAws size={logoSize}/>,
    link: "https://aws.amazon.com/",
    title: "AWS is our major cloud service provider"
  },
  {
    logo: <SiAmazons3 size={logoSize}/>,
    link: "https://aws.amazon.com/s3/",
    title: "We use Amazon S3 for our object storage"
  },
  {
    logo: <FaJava size={logoSize}/>,
    link: "https://www.java.com/",
    title: "Java is the main programming language for our backend"
  },
  {
    logo: <SiTypescript size={logoSize}/>,
    link: "https://www.typescriptlang.org/",
    title: "TypeScript is the main programming language for our frontend"
  },
  {
    logo: <FaPython size={logoSize}/>,
    link: "https://www.python.org/",
    title: "We use Python to build a code base management infrastructure"
  },
]

const widgetId = getObjectId(1);

const IndexWidget = ({sx, ...rest}: StackProps) => {
  return <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} spacing={4}
                width={"100%"} minHeight={"76vh"}
                sx={{
                  ...sx
                }} // You can adjust the styling as needed
                {...rest}
  >
    <Stack direction={"column"} alignItems={"center"} spacing={2}>
      <ImageContainer src={"/hive.png"} sx={{width: "120px", height: "120px"}}/>
      <Typography variant={"h3"}>{"My Dimension"}</Typography>
      <Typography variant={"h6"}>{"Start your own web service"}</Typography>
    </Stack>
    <Grid container spacing={8} alignItems={"center"} justifyContent={"center"}>
      <ListComponent data={logos} renderItem={(item, index) => {
        return <Tooltip title={item.title} key={index}>
          <Link href={item.link} target={DTARGET} className={"logo-icon pointer"}
                sx={{color: "inherit"}}>
            {item.logo}
          </Link>
        </Tooltip>
      }}/>
    </Grid>
    <FeedbackWidget resourceId={widgetId} resourceType={ResourceType.RESOURCE_TYPE_WEBPAGE}/>
  </Stack>
}

export default React.memo(IndexWidget);