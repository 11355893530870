import * as React from 'react';
import {useCallback, useEffect} from 'react';
import {logAction, useClientLog} from "../../../context/websocket/WebsocketHook";
import {NotificationContext} from "../../../context/NotificationContext";
import {useLocation} from "react-router-dom";

const ClientLogger = ({init = false, freq = 3 * 1000}) => {
  const location = useLocation();
  void useClientLog(init, freq);
  const {unReadNotification, ack} = React.useContext(NotificationContext);
  const resolveNotification = useCallback(() => {
    const idsToAck = [];
    for (let n of unReadNotification) {
      if (n.link && window.location.href.includes(n.link)) {
        idsToAck.push(n.id);
      }
    }
    if (idsToAck.length > 0) {
      void ack(idsToAck, true);
    }
  }, [ack, unReadNotification]);
  useEffect(() => {
    void logAction(window.location.href, "route");
    resolveNotification();
  }, [location, resolveNotification]);

  return (
      <>
      </>
  );
};

export default ClientLogger;