import React, {useCallback, useEffect} from 'react';
import {NotificationProfile} from "../proto/framework/notification/NotificationMessage";
import {useAppSelector} from "../api/redux/hooks";
import {selectLoginResponse} from "../api/redux/slice/SettingSlice";
import {ackNotification, queryNotification} from "../domain/notification/NotificationService";
import {useSocket} from "./websocket/WebsocketHook";
import {SocketChannel} from "../proto/framework/websocket/WebsocketMessage";
// import {DimensionResponse} from "../model/DimensionResponse.type";

const NotificationContext = React.createContext({
  unReadNotification: [] as NotificationProfile[],
  ack: (ids: string[] = [], remove = false): Promise<void> => Promise.resolve(),
});

const useNotificationProvider = () => {
  const loginResponse = useAppSelector(selectLoginResponse);
  const [unreadNotification, setUnreadNotification] = React.useState<NotificationProfile[]>([]);

  const updateNotification = useCallback(() => {
    queryNotification(true).then((res) => {
      const notificationCanBeAcked = res.notifications.filter((n) => n.link && window.location.href.includes(n.link));
      if (notificationCanBeAcked.length > 0) {
        ackNotification(notificationCanBeAcked.map((n) => n.id), true).then(() => {
          setUnreadNotification(res.notifications.filter((n) => !notificationCanBeAcked.includes(n)));
        }).catch((e) => {
        })
      } else {
        setUnreadNotification(res.notifications);
      }
    }).catch((e) => {
    });
  }, []);

  useSocket("notification_context", () => {
    updateNotification();
  }, SocketChannel.NOTIFICATION, "new_notification");

  useEffect(() => {
    if (!loginResponse?.userId) {
      return;
    }
    queryNotification(true).then((res) => {
      updateNotification();
    }).catch((e) => {
    })
  }, [loginResponse, updateNotification]);

  const ack = (ids: string[] = [], remove = false) => {
    return ackNotification(ids, remove).then(() => {
      setUnreadNotification(unreadNotification.filter((n) => !ids.includes(n.id)));
    }).catch((e) => {

    });
  };

  return {unReadNotification: unreadNotification, ack};
}

export {NotificationContext, useNotificationProvider};