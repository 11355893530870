import * as React from 'react';
import {Badge, BadgeProps} from "@mui/material";
import {NotificationContext} from "../../../context/NotificationContext";

const NotificationBadge = (props: BadgeProps) => {
 const {unReadNotification} = React.useContext(NotificationContext);

  return <Badge
      color={"error"}
      badgeContent={unReadNotification.length}
      overlap="circular"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props} />;
};

export default NotificationBadge;