// 码元长度
export const pointLength = (str: string) => {
  let length = 0;
  for (let i = 0; i < str.length;) {
    length++;
    const point = str.codePointAt(i) ?? 0;
    i += point > 0xffff ? 2 : 1;
  }
  return length;
}

export const pointAt = (str: string, index: number) => {
  let i = 0;
  for (let j = 0; j < str.length;) {
    if (i === index) {
      const point = str.codePointAt(j);
      return point ? String.fromCodePoint(point) : void 0;
    }
    const point = str.codePointAt(j) ?? 0;
    j += point > 0xffff ? 2 : 1;
    i++;
  }
  return void 0;
}

export const pointSlice = (str: string, start: number, end?: number) => {
 let result = '';
 const length = pointLength(str);
  if (end === void 0) {
    end = length;
  }
 for (let i = start; i < length && i < end; i++) {
   result += pointAt(str, i) ?? '';
 }
  return result;
}

export const getObjectId = (n: number) => {
  return n.toString(16).padStart(24, '0');
}

export const toReadableNumber = (n: number) => {
  if (n < 1000) {
    return n.toString();
  }
  if (n < 1000000) {
    return (n / 1000).toFixed(1) + 'k';
  }
  if (n < 1000000000) {
    return (n / 1000000).toFixed(1) + 'm';
  }
  return (n / 1000000000).toFixed(1) + 'b';
}