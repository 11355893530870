// @flow
import * as React from 'react';
import {useIsAdmin} from "../../hook/CommonUiHook";

type Props = {
  children?: React.ReactNode | React.ReactNode[];
};
export const RequireAdmin = (props: Props) => {
  const isAdmin = useIsAdmin();
  return isAdmin ? <>{props.children}</> : <></>;
};