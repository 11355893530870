import * as React from 'react';
import {Stack, StackProps, SxProps} from "@mui/material";
import {ResourceType} from "../../proto/framework/ResourceMessage";
import {CollectorType} from "../../proto/framework/collector/CollectorMessage";
import {useEffect} from "react";
import * as collector from "./CollectorService";
import AlertContext from "../../context/AlertContext";
import ListComponent from "../../component/container/ListComponent";
import CollectorButton from "./component/CollectorButton";
import {print} from "../../api/util/Logging";
import {useAppSelector} from "../../api/redux/hooks";
import {selectLoginResponse} from "../../api/redux/slice/SettingSlice";
import {isReadOnly} from "./CollectorService";
import {IconBaseProps} from "react-icons/lib/iconBase";
import {TypographyProps} from "@mui/material/Typography";

type CollectorWidgetProps = {
  resourceType: ResourceType;
  resourceId: string;
  collectType: CollectorType[];
  readonly?: boolean;
  iconProps?: IconBaseProps;
  numberProps?: TypographyProps;
  eleSx?: SxProps;
} & StackProps;
// for simple counting collector
const CollectorWidget = ({
                           resourceType,
                           resourceId,
                           collectType,
                           readonly = false,
                           iconProps,
                           numberProps,
                           eleSx,
                           sx, ...rest
                         }: CollectorWidgetProps) => {

  const {showAlert} = React.useContext(AlertContext);
  const loginResponse = useAppSelector(selectLoginResponse);
  const hasLogin = loginResponse?.userId && loginResponse.userId.length > 0;

  // useDynamicList
  const initialState = collectType.map((type) => {
    return {
      type: type,
      number: 0,
      collected: false,
      readOnly: hasLogin ? isReadOnly(type) : true
    }
  });

  const [collectorState, setCollectorState] = React.useState(initialState);

  const increment = (type: CollectorType) => {
    const newState = collectorState.map((state) => {
      if (state.type === type && !state.readOnly && !state.collected) {
        return {
          ...state,
          number: state.number + 1,
          collected: true
        }
      }
      return state;
    });
    setCollectorState(newState);
  }

  const decrement = (type: CollectorType) => {
    const newState = collectorState.map((state) => {
      if (state.type === type && !state.readOnly && state.collected && state.number > 0) {
        return {
          ...state,
          number: state.number - 1,
          collected: false
        }
      }
      return state;
    });
    setCollectorState(newState);
  }

  const onCollectChange = (collect: boolean, type: CollectorType) => {
    // update collect state
    if (collect) {
      collector.collect(type, resourceType, resourceId).then((response) => {
        increment(type);
      });
    } else {
      collector.unCollect(type, resourceType, resourceId).then((response) => {
        decrement(type);
      });
    }
  }

  useEffect(() => {
    collector.queryProfile(resourceType, [resourceId]).then((response) => {
      if (response.profiles.length !== 1) {
        showAlert("Invalid profile response", "error");
        print("Invalid profile response", response);
        return;
      }
      const profile = response.profiles[0];
      if (profile.targetProfile) {
        for (const collectorTargetProfile of profile.targetProfile.profiles) {
          const state = collectorState.find((state) => {
            return state.type === collectorTargetProfile.type;
          })
          if (state) {
            state.number = collectorTargetProfile.count;
          }
        }
      }
      if (profile.userCollectState) {
        for (const userCollectorState of profile.userCollectState.collectStates) {
          const state = collectorState.find((state) => {
            return state.type === userCollectorState.type;
          })
          if (state) {
            state.collected = true;
          }
        }
      }
      setCollectorState([...collectorState]);
    });
  }, [resourceId, resourceType, showAlert]);

  return <Stack direction={"row"} alignItems={"center"}
                spacing={4}
                justifyContent={{
                  xs: "center",
                  sm: "flex-start"
                }}
                sx={{
                  width: "100%",
                  ...sx
                }} {...rest}>
    <ListComponent data={collectorState} renderItem={(item, index) => {
      return <CollectorButton key={index}
                              collectorType={item.type}
                              number={item.number}
                              readOnly={item.readOnly || readonly}
                              collected={item.collected}
                              onCollectChange={(collect) => {
                                onCollectChange(collect, item.type)
                              }
                              }
                              iconProps={{size: 32, ...iconProps}}
                              numberProps={{variant: "h5", ...numberProps}}
                              sx={{
                                backgroundColor: "#E5E5E5",
                                paddingX: 2,
                                paddingY: 1,
                                borderRadius: 20,
                                ...eleSx
                              }}
      />
    }}/>
  </Stack>
};

export default CollectorWidget;