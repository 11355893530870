// @flow
import * as React from 'react';
import {CollectorType} from "../../../proto/framework/collector/CollectorMessage";
import {AiFillLike, AiOutlineLike} from "react-icons/ai";
import {FaRegStar, FaStar} from "react-icons/fa";
import {AiFillDislike, AiOutlineDislike} from "react-icons/ai";
import {HiEye, HiOutlineEye} from "react-icons/hi";
import {IoDownload, IoDownloadOutline} from "react-icons/io5";
import {SlUserFollow, SlUserFollowing} from "react-icons/sl";
import {MdOutlineRateReview, MdRateReview} from "react-icons/md";
import {FaRegCommentDots, FaCommentDots} from "react-icons/fa";
import {IconBaseProps} from "react-icons/lib/iconBase";
import {useTranslation} from "react-i18next";

type Props = {
  collectorType: CollectorType;
  collected: boolean;
} & IconBaseProps;
const CollectorIcon = ({collectorType, collected, ...rest}: Props) => {
  const {t} = useTranslation();
  switch (collectorType) {
    case CollectorType.COLLECTOR_TYPE_LIKE:
      return collected ? <AiFillLike title={t("like")} {...rest}/> :
          <AiOutlineLike title={t("like")} {...rest}/>;
    case CollectorType.COLLECTOR_TYPE_DISLIKE:
      return collected ? <AiFillDislike title={t("unlike")} {...rest}/> :
          <AiOutlineDislike title={t("unlike")} {...rest}/>;
    case CollectorType.COLLECTOR_TYPE_VIEW:
      return collected ? <HiEye title={t("view")} {...rest}/> :
          <HiOutlineEye title={t("view")}  {...rest}/>;
    case CollectorType.COLLECTOR_TYPE_DOWNLOAD:
      return collected ? <IoDownload title={t("download")}  {...rest}/> :
          <IoDownloadOutline title={t("download")}  {...rest}/>;
    case CollectorType.COLLECTOR_TYPE_FOLLOW:
      return collected ? <SlUserFollowing title={t("follow")} {...rest}/> :
          <SlUserFollow title={t("follow")} {...rest}/>;
    case CollectorType.COLLECTOR_TYPE_EVALUATION:
      return collected ? <MdRateReview title={t("rating")} {...rest}/> :
          <MdOutlineRateReview title={t("rating")} {...rest}/>;
    case CollectorType.COLLECTOR_TYPE_COMMENT:
      return collected ? <FaCommentDots title={t("collect")} {...rest}/> :
          <FaRegCommentDots title={t("collect")} {...rest}/>;
    case CollectorType.COLLECTOR_TYPE_COLLECTION:
    default:
      return collected ? <FaStar {...rest}/> : <FaRegStar {...rest}/>;
  }
};

export default CollectorIcon;