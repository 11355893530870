import React, {useState} from 'react';
import {IconButton, Stack, StackProps, TextFieldProps} from '@mui/material';
import EmojiPicker, {EmojiStyle} from 'emoji-picker-react';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import FunctionalTextArea from "./FunctionalTextArea";
import {useAutoFocus} from "../../hook/CommonUiHook";

type EmojiTextFieldProps = {
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
  label?: string;
  placeholder?: string;
  minRows?: number;
  variant?: TextFieldProps['variant'];
  focus?: boolean;
  children?: React.ReactNode;
} & StackProps

const EmojiTextField = ({
                          text,
                          setText,
                          label = void 0,
                          placeholder = "Type a message",
                          variant = "outlined",
                          minRows = 1,
                          children,
                          focus,
                          ...rest
                        }: EmojiTextFieldProps) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const inputRef = useAutoFocus([focus]);
  const selfRef = React.useRef<HTMLTextAreaElement>(null);
  const textAreaRef = focus === undefined ? selfRef : inputRef;
  const handleEmojiClick = (emojiObject: any) => {
    const areaRef = textAreaRef as React.MutableRefObject<HTMLTextAreaElement>;
    // get current cursor position
    const currentValue = areaRef.current?.value;
    if (!currentValue) {
      setText(emojiObject.emoji);
    }
    const cursorStart = areaRef.current?.selectionStart || currentValue.length;
    let cursorEnd = areaRef.current?.selectionEnd || cursorStart;
    if (cursorEnd < cursorStart) {
      cursorEnd = cursorStart;
    }
    setText(currentValue.substring(0, cursorStart) + emojiObject.emoji + currentValue.substring(cursorEnd));
    areaRef.current.focus();
  };

  return (
      <Stack alignItems={"end"} width={"100%"} {...rest}>
        <FunctionalTextArea label={label}
                            placeholder={placeholder}
                            variant={variant}
                            value={text}
                            minRows={minRows}
                            inputRef={textAreaRef}
            // ref={selfRef as any}
                            onChange={(e) => setText(e.target.value)}>
          <IconButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
            <EmojiEmotionsIcon/>
          </IconButton>
          {children}
        </FunctionalTextArea>
        <EmojiPicker
            open={showEmojiPicker}
            onEmojiClick={handleEmojiClick}
            skinTonesDisabled={true}
            // onSkinToneChange={(skinTone: any) => console.log(skinTone)}
            emojiStyle={EmojiStyle.NATIVE}
            width={"100%"}/>
      </Stack>
  );
};

export default EmojiTextField;