import * as React from 'react';
import {ResourceType} from "../../../proto/framework/ResourceMessage";
import CollectorWidget from "../../collector/CollectorWidget";
import {CollectorType} from "../../../proto/framework/collector/CollectorMessage";
import ViewRecorder from "../../collector/component/ViewRecorder";
import CommentWidget from "../../comment/CommentWidget";

// a typical feedback widget that includes collector, view recorder and comment widget

type Props = {
  resourceType?: ResourceType;
  resourceId: string;
};
const FeedbackWidget = ({resourceType = ResourceType.RESOURCE_TYPE_WEBPAGE, resourceId}: Props) => {
  return <>
    <CollectorWidget
        resourceId={resourceId}
        resourceType={resourceType}
        collectType={[CollectorType.COLLECTOR_TYPE_VIEW, CollectorType.COLLECTOR_TYPE_LIKE]}
    />
    <ViewRecorder resourceId={resourceId}
                  resourceType={resourceType}/>
    <CommentWidget objectId={resourceId} targetType={resourceType}/>

  </>
};

export default React.memo(FeedbackWidget);