// @flow
import * as React from 'react';
import {useTheme} from "@mui/material";
import {useAutoFocus, useFieldValidation, useHandleDialogClose} from "../../../hook/CommonUiHook";
import {CloseableDialogProps} from "../../../component/dialog/DialogManager";
import {setDescription} from "../service/CustomerService";
import DimensionDialog from "../../../component/dialog/DimensionDialog";
import Divider from "@mui/material/Divider";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DimensionForm from "../../../component/container/DimensionForm";
import ClearableTextField from "../../../component/input/ClearableTextField";
import {useAppSelector} from "../../../api/redux/hooks";
import {selectInitialData} from "../../../api/redux/slice/DialogSlice";

type IType = {
  description: string;
}

export const EditCustomerDescriptionDialog = React.memo(({onDialogClose,initialData, open, ...rest}: CloseableDialogProps<IType>) => {
  const theme = useTheme();
  const handleClose = useHandleDialogClose<string>("EditCustomerDescriptionDialog", onDialogClose);
  const initialDataFromManager = useAppSelector(selectInitialData<IType>);

  const [newFieldValue, setNewFieldValue, errorMessage, setErrorMessage, validate] = useFieldValidation((newFieldValue) => {
    if (newFieldValue.length > 1000) {
      return "Description should less than 1000 characters";
    }
  }, initialData?.description || initialDataFromManager?.description || "");

  const inputRef = useAutoFocus([open], (inputRef) => {
    inputRef.current?.setSelectionRange(0, newFieldValue.length);
  });

  const handleEditFieldValue = async () => {
    if (!validate()) {
      return;
    }

    setDescription(newFieldValue).then(response => {
      handleClose(true, response.description);
    }).catch(error => {
      setErrorMessage(error.message);
    });
  }

  return <DimensionDialog
      dialogTitle={"Edit Announcement"}
      titleSx={{background: `linear-gradient(${theme.palette.warning.light}, #ffffff)`}}
      dialogStartNode={<Divider><DriveFileRenameOutlineIcon className={"awesome-icon"}/></Divider>}
      onDialogClose={() => handleClose(false)}
      primaryText={"Update"}
      open={open}
      onDialogSubmit={handleEditFieldValue}
      {...rest}>
    <DimensionForm
        infoMessage={"Share your thoughts on your home page."}>
      <ClearableTextField value={newFieldValue} setValue={setNewFieldValue} label={"New Announcement"}
                          onSubmit={handleEditFieldValue} errorMessage={errorMessage}
                          inputRef={inputRef}/>
    </DimensionForm>
  </DimensionDialog>
});