import * as React from 'react';
import {useEffect} from 'react';
import {CloseableDialogProps} from "../../../component/dialog/DialogManager";
import {Stack, useTheme} from "@mui/material";
import {useAutoFocus, useFieldValidation, useHandleDialogClose} from "../../../hook/CommonUiHook";
import {useAppSelector} from "../../../api/redux/hooks";
import {setAvatarUrl} from "../service/CustomerService";
import DimensionDialog from "../../../component/dialog/DimensionDialog";
import Divider from "@mui/material/Divider";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DimensionForm from "../../../component/container/DimensionForm";
import ClearableTextField from "../../../component/input/ClearableTextField";
import {validateUrl} from "../../../api/util/Validation";
import ProfileAvatar from "./ProfileAvatar";
import {selectLoginResponse} from "../../../api/redux/slice/SettingSlice";
import Dropzone from "react-dropzone";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {Button, Typography} from "../../../component/wrapper/MuiWrappers";
import AlertContext from "../../../context/AlertContext";
import {compressImage} from "../../../api/util/ImageUtil";
import {uploadFile} from "../../storage/FileUploadUtil";
import ReactCrop, {type Crop} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

export const EditAvatarDialog = React.memo(({
                                       onDialogClose,
                                       initialData,
                                       open,
                                       ...rest
                                     }: CloseableDialogProps) => {
  const theme = useTheme();
  const loginResponse = useAppSelector(selectLoginResponse);
  const [loading, setLoading] = React.useState(false);
  const {showAlert} = React.useContext(AlertContext)
  const handleClose = useHandleDialogClose<string>("EditAvatarDialog", onDialogClose);

  const [newFieldValue, setNewFieldValue, errorMessage, setErrorMessage, validate] = useFieldValidation((newFieldValue) => {
    if (!validateUrl(newFieldValue)) {
      return "Invalid URL for Avatar";
    }
  });

  const [file, setFile] = React.useState<File | undefined>(void 0);
  const [fileToUpload, setFileToUpload] = React.useState<File | undefined>(void 0);
  const [localFileUrl, setLocalFileUrl] = React.useState<string | undefined>(void 0);
  const [previewUrl, setPreviewUrl] = React.useState<string | undefined>(void 0);
  const [crop, setCrop] = React.useState<Crop>();
  // const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>();
  const previewCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const imgRef = React.useRef<HTMLImageElement>(null);

  const getCropBlob = (c: Crop) => {
    const cvs = previewCanvasRef.current;
    const ctx = previewCanvasRef.current?.getContext('2d');
    const img = imgRef.current;
    if (!ctx || !cvs || !img) {
      return;
    }

    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;

    cvs.width = c.width
    cvs.height = c.height

    ctx.drawImage(
        img,
        c.x * scaleX,
        c.y * scaleY,
        c.width * scaleX,
        c.height * scaleY,
        0,
        0,
        cvs.width,
        cvs.height
    );

    cvs.toBlob((blob) => {
          if (blob === void 0 || blob === null) {
            return;
          }
          const url = URL.createObjectURL(blob);
          setPreviewUrl(url);
          const newFile = new File([blob], file?.name || "preview.jpg", {type: file?.type || "image/jpeg"});
          setFileToUpload(newFile);
        }
    );

  }

  useEffect(() => {
    if (localFileUrl !== void 0) {
      URL.revokeObjectURL(localFileUrl);
    }
    setLocalFileUrl(file ? URL.createObjectURL(file) : void 0);

    if (previewUrl !== void 0) {
      URL.revokeObjectURL(previewUrl);
    }
    setPreviewUrl(file ? URL.createObjectURL(file) : void 0);

    return () => {
      if (localFileUrl !== void 0) {
        URL.revokeObjectURL(localFileUrl);
      }
      if (previewUrl !== void 0) {
        URL.revokeObjectURL(previewUrl);
      }
    }
  }, [file]);

  const inputRef = useAutoFocus([open], (inputRef) => {
    inputRef.current?.select();
  });

  const getPreviewUrl = () => {
    if (previewUrl) {
      return previewUrl;
    }

    if (localFileUrl) {
      return localFileUrl;
    }
    if (newFieldValue && validateUrl(newFieldValue)) {
      return newFieldValue
    }

    return loginResponse?.avatarUrl || "";
  }

  const handleEditFieldValue = async () => {
    setLoading(true);

    const finalFile = fileToUpload ?? file;

    if (finalFile !== void 0) {
      try {
        // compress image
        const compressedFile = await compressImage(finalFile);
        // upload file
        const fileMetaData = await uploadFile(compressedFile, true);
        // set avatar url
        const response = await setAvatarUrl(fileMetaData.url);
        handleClose(true, response.avatarUrl);
      } catch (error) {
        setErrorMessage("File upload failed, please retry");
      } finally {
        setLoading(false);
      }
      // set avatar url
      return;
    }
    if (!validate()) {
      setLoading(false);
      return;
    }
    setLoading(true);
    setAvatarUrl(newFieldValue).then(response => {
      handleClose(true, response.avatarUrl);
    }).catch(error => {
      setErrorMessage(error.message);
    }).finally(() => {
      setLoading(false);
    });
  }

  return <DimensionDialog
      dialogTitle={"Edit Avatar"}
      titleSx={{background: `linear-gradient(${theme.palette.warning.light}, #ffffff)`}}
      dialogStartNode={<Divider><DriveFileRenameOutlineIcon className={"awesome-icon"}/></Divider>}
      onDialogClose={() => handleClose(false)}
      primaryText={"Update"}
      open={open}
      onDialogSubmit={handleEditFieldValue}
      {...rest}>
    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
      <ProfileAvatar avatarUrl={getPreviewUrl()}
                     title={loginResponse?.name || "User"}
                     sx={{
                       width: "96px",
                       height: "96px",
                       fontSize: "50px",
                     }}
                     style={{
                       cursor: "pointer",
                       width: "96px",
                       height: "96px",
                       fontSize: "50px",
                       border: "5px solid",
                       borderColor: "#4285F4 #DB4437 #F4B400 #0F9D58",
                     }}/>
    </Stack>
    <DimensionForm
        infoMessage={"Use an image URL or upload an image."}>
      {file === void 0 &&
          <ClearableTextField required={false} value={newFieldValue} setValue={setNewFieldValue}
                              label={"Image URL"}
                              onSubmit={handleEditFieldValue} errorMessage={errorMessage}
                              inputRef={inputRef}/>}
      <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
        {file === void 0 && <Dropzone disabled={loading} onDrop={fileList => {
          if (!fileList || fileList.length === 0) {
            return;
          }
          const file = fileList[0];
          if (!file.type.startsWith("image")) {
            showAlert("File format not supported", "error");
            return;
          }
          setFile(file);
        }
        }>
          {({getRootProps, getInputProps}) => (
              <section {...getRootProps()} style={{width: "100%"}}>
                <Stack direction={"row"} spacing={2} border={"dashed"} padding={2}
                       justifyContent={"center"} alignItems={"center"}>
                  <Stack minHeight={300} justifyContent={"center"} alignItems={"center"}>
                    <FileUploadIcon sx={{
                      fontSize: {
                        xs: "2rem",
                        sm: "4rem",
                        md: "5rem"
                      }
                    }}/>
                    <Typography sx={{
                      fontSize: {
                        xs: "1.5rem",
                        sm: "1.5rem",
                        md: "1.5rem"
                      },
                      textAlign: "center"
                    }}>{"Drop You File Here"}</Typography>
                  </Stack>
                  <input type={"file"} multiple={true} {...getInputProps()}
                         hidden/>
                </Stack>
              </section>
          )}
        </Dropzone>}
        {/*image cropper here*/}
        {file !== void 0 && <Stack spacing={1}>
          <ReactCrop crop={crop} onChange={c => setCrop(c)} onComplete={(c) => {
            getCropBlob(c);
          }}>
            <img src={localFileUrl} ref={imgRef}/>
          </ReactCrop>
          <Button variant={"contained"}
                  disabled={loading}
                  color={"error"}
                  disableElevation={true}
                  onClick={() => {
                    setFile(void 0);
                  }}>{"Re Upload"}</Button>
          <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: crop?.width,
                height: crop?.height,
                display: 'none',
              }}
          />
        </Stack>
        }
      </Stack>
    </DimensionForm>
  </DimensionDialog>;
});
