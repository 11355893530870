// @flow 
import * as React from 'react';
import FileUploadDialog from "../../../../component/dialog/FileUploadDialog";
import {CloseableDialogProps} from "../../../../component/dialog/DialogManager";
import {useHandleDialogClose} from "../../../../hook/CommonUiHook";
import {addObjectFile} from "../../service/DriverService";
import {useAppSelector} from "../../../../api/redux/hooks";
import {selectInitialData} from "../../../../api/redux/slice/DialogSlice";

export type DriverUploadDialogIType = {
  path: string;
  public: boolean;
}

export const DriverUploadDialog = React.memo(({
                                                onDialogClose,
                                                initialData,
                                                open,
                                                ...rest
                                              }: CloseableDialogProps<DriverUploadDialogIType>) => {

  const handleClose = useHandleDialogClose("DriverUploadDialog", onDialogClose);
  const initialDataFromManager = useAppSelector(selectInitialData<DriverUploadDialogIType>);

  const path = initialData?.path || initialDataFromManager?.path || "/";
  const isPublic = initialData?.public || initialDataFromManager?.public || false;

  return <FileUploadDialog open={open}
                           isPublic={isPublic}
                           onUpload={async (fileMetaData) => {
                             return addObjectFile(fileMetaData.name, path, fileMetaData.id, isPublic).then(() => void 0);
                           }}
                           onDialogClose={() => {
                             handleClose(false);
                           }} onDialogSubmit={(allFiles, success) => void 0}   {...rest}
  />;
});
