import * as React from 'react';
import {useViewRecorder} from "../CollectorHooks";
import {ResourceType} from "../../../proto/framework/ResourceMessage";

type Props = {
  resourceType: ResourceType,
  resourceId: string,
  timeInSeconds?: number
};
const ViewRecorder = ({
                        resourceType,
                        resourceId,
                        timeInSeconds = 5
                      }: Props) => {
  useViewRecorder(resourceType, resourceId, timeInSeconds);
  return <></>;
};

export default React.memo(ViewRecorder);
