// @flow
import * as React from 'react';
import {useTheme} from "@mui/material";
import {useAutoFocus, useFieldValidation, useHandleDialogClose} from "../../../hook/CommonUiHook";
import {CloseableDialogProps} from "../../../component/dialog/DialogManager";
import {validateUsername} from "../../../api/util/Validation";
import DimensionDialog from "../../../component/dialog/DimensionDialog";
import Divider from "@mui/material/Divider";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DimensionForm from "../../../component/container/DimensionForm";
import ClearableTextField from "../../../component/input/ClearableTextField";
import {setCustomerName} from "../service/CustomerService";
import {useAppSelector} from "../../../api/redux/hooks";
import {selectInitialData} from "../../../api/redux/slice/DialogSlice";

type IType = {
  username: string;
}

export const EditCustomerNameDialog = React.memo(({
                                         onDialogClose,
                                         initialData,
                                         open,
                                         ...rest
                                       }: CloseableDialogProps<IType>) => {
  const theme = useTheme();
  const handleClose = useHandleDialogClose<string>("EditCustomerNameDialog", onDialogClose);
  const initialDataFromManager = useAppSelector(selectInitialData<IType>);


  const [newUsername, setNewUsername, usernameErrorMessage, setUsernameErrorMessage, validate] = useFieldValidation((username) => {
    if (username.length === 0) {
      return "Username cannot be empty";
    }
    if (!validateUsername(username)) {
      return "Username is invalid";
    }
  }, initialData?.username || initialDataFromManager?.username || "");

  const inputRef = useAutoFocus([open], (inputRef) => {
    inputRef.current?.setSelectionRange(0, newUsername.length);
  });

  const handleEditUsername = async () => {
    if (!validate()) {
      return;
    }

    setCustomerName(newUsername).then(response => {
      handleClose(true, response.name);
    }).catch(error => {
      setUsernameErrorMessage(error.message);
    });
  }

  return <DimensionDialog
      dialogTitle={"Edit Public Username"}
      titleSx={{background: `linear-gradient(${theme.palette.warning.light}, #ffffff)`}}
      dialogStartNode={<Divider><DriveFileRenameOutlineIcon className={"awesome-icon"}/></Divider>}
      onDialogClose={() => handleClose(false)}
      primaryText={"Update"}
      open={open}
      onDialogSubmit={handleEditUsername}
      {...rest}>
    <DimensionForm
        infoMessage={"Username is a public name for you."}>
      <ClearableTextField value={newUsername} setValue={setNewUsername} label={"New Username"}
                          onSubmit={handleEditUsername} errorMessage={usernameErrorMessage}
                          inputRef={inputRef}/>
    </DimensionForm>
  </DimensionDialog>
});