import * as React from 'react';
import {Outlet} from "react-router-dom";
import "./fullscreen.css";
import ClientLogger from "../component/ClientLogger";
import {enable_client_log} from "../../../api/applicationConfig";
import PortalFooter from "../footer/PortalFooter";
// FullScreen 是一个全屏的3D展示页面 没有导航栏
const FullScreen = () => {
  return <div>
    <ClientLogger init={enable_client_log} freq={3000}/>
    <Outlet/>
    <PortalFooter/>
  </div>;
};

export default FullScreen;