import * as React from 'react';
import {useCallback} from 'react';
import {
  ClientAction,
  SocketChannel,
  WebsocketMessage
} from "../../proto/framework/websocket/WebsocketMessage";
import {WebsocketContext} from "./WebsocketContext";
import {print} from "../../api/util/Logging";
import {pb_strPost, ProtoDecode, ProtoEncode} from "../../api/util/MyDimensionClient";

const LOG_QUEUE: WebsocketMessage[] = [];

export const wrapSocketMessage = <T>(encoder: ProtoEncode<T>, content: T, message: WebsocketMessage) => {
  message.binaryContent = encoder.encode(content).finish();
  return message;
}

export const debugAction = (action: string) => {
  logAction(action, "debug");
}

export const logAction = (action: string, subChannel: string) => {
  return new Promise((resolve) => {
    const actionMessage = WebsocketMessage.create();
    actionMessage.channel = SocketChannel.CLIENT_LOG;
    actionMessage.subChannel = subChannel;

    const clientAction = ClientAction.create();
    clientAction.action = action;
    clientAction.timestamp = Date.now();

    wrapSocketMessage(ClientAction, clientAction, actionMessage);

    LOG_QUEUE.push(actionMessage);
    resolve(void 0);
  });
}

const is_message_eq = (a: WebsocketMessage, b: WebsocketMessage) => {
  return a.channel === b.channel && a.subChannel === b.subChannel && a.timestamp === b.timestamp && a.text === b.text && a.binaryContent === b.binaryContent;
}

const backUpLog = (message: WebsocketMessage) => {
  return pb_strPost("/api/v0/logging/log", WebsocketMessage, message, void 0, true);
}

const logLimit = 3;

export const useClientLog = (init = false, freq = 3 * 1000) => {
  const {sendSocketMessage, connected} = React.useContext(WebsocketContext);

  const [start, setStart] = React.useState(init);

  const logging = useCallback(() => {
    if (start) {
      let prevMessage = null;
      let count = 0;
      while (LOG_QUEUE.length > 0) {
        count++;
        const message = LOG_QUEUE.shift();
        if (message) {
          if (!prevMessage) {
            prevMessage = message;
          } else {
            if (is_message_eq(prevMessage, message)) {
              // Skip duplicate message
              continue;
            }
            prevMessage = message;
          }
          if (connected) {
            sendSocketMessage(message);
          } else {
            void backUpLog(message);
          }
          if (count > logLimit) {
            // force break
            break;
          }
        }
      }
    }
  },[connected, sendSocketMessage, start]);

  React.useEffect(() => {
    const loggingInterval = setInterval(logging, freq);
    // const loggingTimeOut = setTimeout(logging, freq);

    return () => {
      clearInterval(loggingInterval);
    }
  }, [freq, logging]);

  return {setStart};
}

export const useSocket = (subscriberId: string, callback: (message: WebsocketMessage) => void, channel?: SocketChannel, subChannel?: string) => {
  const {message, sendSocketMessage, subscribeTo, connected} = React.useContext(WebsocketContext);
  subscribeTo(subscriberId, (msg) => {
    if (channel && msg.channel !== channel) {
      return;
    }
    if (subChannel && msg.subChannel !== subChannel) {
      return;
    }
    callback(msg);
  });
  return {message, sendSocketMessage, connected};
}

export const useSocketText = (subscriberId: string, callback: (message: string) => void, channel?: SocketChannel, subChannel?: string) => {
  return useSocket(subscriberId, (msg) => {
    if (msg.text) {
      callback(msg.text);
    } else {
      print("Message is not text", msg);
    }
  }, channel, subChannel);
}

export const useSocketJson = (subscriberId: string, callback: (message: object) => void, channel?: SocketChannel, subChannel?: string) => {
  return useSocket(subscriberId, (msg) => {
    if (msg.json) {
      callback(JSON.parse(msg.json));
    } else {
      print("Message is not json", msg);
    }
  }, channel, subChannel);
}

export const useSocketPb = <K>(subscriberId: string, returnType: ProtoDecode<K>, callback: (message: K) => void, channel?: SocketChannel, subChannel?: string) => {
  return useSocket(subscriberId, (msg) => {
    if (msg.binaryContent) {
      callback(returnType.decode(msg.binaryContent));
    } else {
      print("Message is not binary", msg);
    }
  }, channel, subChannel);
}


export const useSubscriber = () => {
  const {sendSocketMessage} = React.useContext(WebsocketContext);
  const [subscribed, setSubscribed] = React.useState<SocketChannel[]>([]);

  const subScribeToChannel = (channel: SocketChannel) => {
    if (subscribed.includes(channel)) {
      return;
    }
    const message = WebsocketMessage.create();
    message.channel = SocketChannel.SUBSCRIBE;
    message.text = channel.toString();

    sendSocketMessage(message);
    setSubscribed([...subscribed, channel]);
  }

  const unSubScribeToChannel = (channel: SocketChannel) => {
    if (!subscribed.includes(channel)) {
      return;
    }
    const message = WebsocketMessage.create();
    message.channel = SocketChannel.UNSUBSCRIBE;
    message.text = channel.toString();

    sendSocketMessage(message);
    setSubscribed(subscribed.filter((c) => c !== channel));
  }

  return {subScribeToChannel, unSubScribeToChannel};

}